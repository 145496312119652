(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.dL.bh === region.d7.bh)
	{
		return 'on line ' + region.dL.bh;
	}
	return 'on lines ' + region.dL.bh + ' through ' + region.d7.bh;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fz,
		impl.fZ,
		impl.fW,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		ac: func(record.ac),
		dN: record.dN,
		dD: record.dD
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.ac;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.dN;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.dD) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fz,
		impl.fZ,
		impl.fW,
		function(sendToApp, initialModel) {
			var view = impl.f$;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fz,
		impl.fZ,
		impl.fW,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.dK && impl.dK(sendToApp)
			var view = impl.f$;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.bQ);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.cZ) && (_VirtualDom_doc.title = title = doc.cZ);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.fI;
	var onUrlRequest = impl.fJ;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		dK: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.eF === next.eF
							&& curr.dp === next.dp
							&& curr.eB.a === next.eB.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		fz: function(flags)
		{
			return A3(impl.fz, flags, _Browser_getUrl(), key);
		},
		f$: impl.f$,
		fZ: impl.fZ,
		fW: impl.fW
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { fv: 'hidden', fa: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { fv: 'mozHidden', fa: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { fv: 'msHidden', fa: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { fv: 'webkitHidden', fa: 'webkitvisibilitychange' }
		: { fv: 'hidden', fa: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		eO: _Browser_getScene(),
		e$: {
			e1: _Browser_window.pageXOffset,
			e2: _Browser_window.pageYOffset,
			e0: _Browser_doc.documentElement.clientWidth,
			ee: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		e0: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ee: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			eO: {
				e0: node.scrollWidth,
				ee: node.scrollHeight
			},
			e$: {
				e1: node.scrollLeft,
				e2: node.scrollTop,
				e0: node.clientWidth,
				ee: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			eO: _Browser_getScene(),
			e$: {
				e1: x,
				e2: y,
				e0: _Browser_doc.documentElement.clientWidth,
				ee: _Browser_doc.documentElement.clientHeight
			},
			fm: {
				e1: x + rect.left,
				e2: y + rect.top,
				e0: rect.width,
				ee: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.fF) { flags += 'm'; }
	if (options.e9) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.fn.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.fn.b, xhr)); });
		$elm$core$Maybe$isJust(request.eZ) && _Http_track(router, xhr, request.eZ.a);

		try {
			xhr.open(request.fD, request.f_, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.f_));
		}

		_Http_configureRequest(xhr, request);

		request.bQ.a && xhr.setRequestHeader('Content-Type', request.bQ.a);
		xhr.send(request.bQ.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.ed; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.fY.a || 0;
	xhr.responseType = request.fn.d;
	xhr.withCredentials = request.e6;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		f_: xhr.responseURL,
		fT: xhr.status,
		fU: xhr.statusText,
		ed: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			fQ: event.loaded,
			eQ: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			fN: event.loaded,
			eQ: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.c) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.e),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.e);
		} else {
			var treeLen = builder.c * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.f) : builder.f;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.c);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.e) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.e);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{f: nodeList, c: (len / $elm$core$Array$branchFactor) | 0, e: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ea: fragment, dp: host, fL: path, eB: port_, eF: protocol, eG: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$document = _Browser_document;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Jurisdiction$NYState = 1;
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {ar: frag, aw: params, ag: unvisited, T: value, aE: visited};
	});
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.aE;
		var unvisited = _v1.ag;
		var params = _v1.aw;
		var frag = _v1.ar;
		var value = _v1.T;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Main$amountParser = A2(
	$elm$url$Url$Parser$questionMark,
	$elm$url$Url$Parser$top,
	$elm$url$Url$Parser$Query$string('amount'));
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aE;
		var unvisited = _v0.ag;
		var params = _v0.aw;
		var frag = _v0.ar;
		var value = _v0.T;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.aE;
			var unvisited = _v0.ag;
			var params = _v0.aw;
			var frag = _v0.ar;
			var value = _v0.T;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $author$project$Main$committeeIdParser = A2(
	$elm$url$Url$Parser$slash,
	$elm$url$Url$Parser$s('committee'),
	$elm$url$Url$Parser$string);
var $author$project$Jurisdiction$MAState = 0;
var $author$project$Jurisdiction$fromString = function (str) {
	switch (str) {
		case 'MA':
			return 0;
		case 'NY':
			return 1;
		default:
			return 1;
	}
};
var $author$project$Main$Hidden = 0;
var $author$project$Main$Open = 1;
var $author$project$Settings$init = {fc: true};
var $author$project$Settings$get = function (committeeId) {
	if (committeeId === 'ian-cain') {
		return {fc: false};
	} else {
		return $author$project$Settings$init;
	}
};
var $author$project$OrgOrInd$Ind = 1;
var $elm$core$Basics$not = _Basics_not;
var $author$project$Settings$toJustOrgOrNothing = function (model) {
	return (!model.fc) ? $elm$core$Maybe$Just(1) : $elm$core$Maybe$Nothing;
};
var $author$project$Main$initModel = F5(
	function (endpoint, committeeId, ref, amount, jurisdiction) {
		var settings = $author$project$Settings$get(committeeId);
		return {
			ah: '',
			aG: '',
			V: amount,
			dW: false,
			ai: false,
			aK: '',
			bT: false,
			aj: '',
			a6: committeeId,
			a7: '',
			H: 1,
			I: 0,
			d4: false,
			aM: '',
			b3: false,
			aN: '',
			J: $elm$core$Maybe$Nothing,
			dj: endpoint,
			_: '',
			b: _List_Nil,
			ap: '',
			aq: '',
			aa: '',
			as: jurisdiction,
			ab: '',
			D: $elm$core$Maybe$Nothing,
			av: $author$project$Settings$toJustOrgOrNothing(settings),
			aT: '',
			aU: '',
			aV: '',
			aW: '',
			aX: '',
			aY: '',
			aZ: '',
			a_: '',
			a$: '',
			m: _List_Nil,
			N: 0,
			cB: false,
			bo: '',
			cC: false,
			ae: '',
			dG: ref,
			ay: $elm$core$Maybe$Nothing,
			dJ: $author$project$Settings$get(committeeId),
			aA: '',
			n: false,
			cX: false,
			O: false
		};
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.ag;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.T);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.T);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.fL),
					$elm$url$Url$Parser$prepareQuery(url.eG),
					url.ea,
					$elm$core$Basics$identity)));
	});
var $author$project$Main$refParser = A2(
	$elm$url$Url$Parser$questionMark,
	$elm$url$Url$Parser$top,
	$elm$url$Url$Parser$Query$string('refCode'));
var $author$project$Main$init = function (_v0) {
	var host = _v0.dp;
	var apiEndpoint = _v0.c9;
	var jurisdiction = _v0.as;
	var _v1 = $elm$url$Url$fromString(host);
	if (!_v1.$) {
		var url = _v1.a;
		var normalizedUrl = _Utils_update(
			url,
			{fL: ''});
		var ref = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Just(''),
				A2($elm$url$Url$Parser$parse, $author$project$Main$refParser, normalizedUrl)));
		var committeeId = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$url$Url$Parser$parse, $author$project$Main$committeeIdParser, url));
		var amount = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Just(''),
				A2($elm$url$Url$Parser$parse, $author$project$Main$amountParser, normalizedUrl)));
		var adminArea = $author$project$Jurisdiction$fromString(jurisdiction);
		return _Utils_Tuple2(
			A5($author$project$Main$initModel, apiEndpoint, committeeId, ref, amount, adminArea),
			$elm$core$Platform$Cmd$none);
	} else {
		return _Utils_Tuple2(
			A5($author$project$Main$initModel, apiEndpoint, '', '', '', 1),
			$elm$core$Platform$Cmd$none);
	}
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Main$GotEmailValidationRes = function (a) {
	return {$: 45, a: a};
};
var $author$project$Main$GotPhoneValidationRes = function (a) {
	return {$: 44, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Main$isValidEmailReceiver = _Platform_incomingPort('isValidEmailReceiver', $elm$json$Json$Decode$value);
var $author$project$Main$isValidNumReceiver = _Platform_incomingPort('isValidNumReceiver', $elm$json$Json$Decode$value);
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Main$isValidNumReceiver($author$project$Main$GotPhoneValidationRes),
				$author$project$Main$isValidEmailReceiver($author$project$Main$GotEmailValidationRes)
			]));
};
var $author$project$Main$Closed = 2;
var $author$project$Main$DonationAmount = 0;
var $author$project$Main$DonorInfo = 1;
var $author$project$EmploymentStatus$Employed = 0;
var $author$project$Main$PaymentDetails = 2;
var $rtfeldman$elm_validate$Validate$Validator = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$ifTrue = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_fromArray(
				[error]) : _List_Nil;
		};
		return getErrors;
	});
var $rtfeldman$elm_validate$Validate$isWhitespaceChar = function (_char) {
	return (_char === ' ') || ((_char === '\n') || ((_char === '\t') || (_char === '\u000D')));
};
var $rtfeldman$elm_validate$Validate$isBlank = function (str) {
	isBlank:
	while (true) {
		var _v0 = $elm$core$String$uncons(str);
		if (!_v0.$) {
			var _v1 = _v0.a;
			var _char = _v1.a;
			var rest = _v1.b;
			if ($rtfeldman$elm_validate$Validate$isWhitespaceChar(_char)) {
				var $temp$str = rest;
				str = $temp$str;
				continue isBlank;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
var $rtfeldman$elm_validate$Validate$ifBlank = F2(
	function (subjectToString, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return $rtfeldman$elm_validate$Validate$isBlank(
					subjectToString(subject));
			},
			error);
	});
var $author$project$Main$amountValidator = A2(
	$rtfeldman$elm_validate$Validate$ifBlank,
	function ($) {
		return $.V;
	},
	'Please choose an amount to donate.');
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Copy$currentDonationExceedsLimit = function (remaining) {
	var remainingString = $elm$core$String$fromFloat(remaining / 100);
	return 'Your current donation exceeds the donation limit. You may donate up to $' + (remainingString + '.');
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Owners$foldOwnership = function (owners) {
	return A3(
		$elm$core$List$foldl,
		$elm$core$Basics$add,
		0,
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					$elm$core$Maybe$withDefault(0),
					$elm$core$String$toFloat),
				function ($) {
					return $.dB;
				}),
			owners));
};
var $author$project$Copy$genericError = 'Unexpected failure, please email us at support@4US.net. Our team is ready to help.';
var $rtfeldman$elm_validate$Validate$all = function (validators) {
	var newGetErrors = function (subject) {
		var accumulateErrors = F2(
			function (_v0, totalErrors) {
				var getErrors = _v0;
				return _Utils_ap(
					totalErrors,
					getErrors(subject));
			});
		return A3($elm$core$List$foldl, accumulateErrors, _List_Nil, validators);
	};
	return newGetErrors;
};
var $author$project$Main$employerValidator = $rtfeldman$elm_validate$Validate$all(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aN;
			},
			'Please specify your employer.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aT;
			},
			'Please specify your occupation.')
		]));
var $author$project$Main$employmentStatusToErrors = function (model) {
	var _v0 = model.as;
	if (!_v0) {
		var _v1 = model.J;
		if (_v1.$ === 1) {
			return _List_fromArray(
				['Please specify your employment status']);
		} else {
			return _List_Nil;
		}
	} else {
		return _List_Nil;
	}
};
var $rtfeldman$elm_validate$Validate$fromErrors = function (toErrors) {
	return toErrors;
};
var $author$project$Main$employmentStatusValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$Main$employmentStatusToErrors);
var $rtfeldman$elm_validate$Validate$ifNothing = F2(
	function (subjectToMaybe, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return _Utils_eq(
					subjectToMaybe(subject),
					$elm$core$Maybe$Nothing);
			},
			error);
	});
var $author$project$Main$familyValidator = A2(
	$rtfeldman$elm_validate$Validate$ifNothing,
	function ($) {
		return $.D;
	},
	'Please specify your family status.');
var $rtfeldman$elm_validate$Validate$firstErrorHelp = F2(
	function (validators, subject) {
		firstErrorHelp:
		while (true) {
			if (!validators.b) {
				return _List_Nil;
			} else {
				var getErrors = validators.a;
				var rest = validators.b;
				var _v1 = getErrors(subject);
				if (!_v1.b) {
					var $temp$validators = rest,
						$temp$subject = subject;
					validators = $temp$validators;
					subject = $temp$subject;
					continue firstErrorHelp;
				} else {
					var errors = _v1;
					return errors;
				}
			}
		}
	});
var $rtfeldman$elm_validate$Validate$firstError = function (validators) {
	var getErrors = function (subject) {
		return A2($rtfeldman$elm_validate$Validate$firstErrorHelp, validators, subject);
	};
	return getErrors;
};
var $author$project$Main$emailAddressToErrors = function (model) {
	return model.b3 ? _List_Nil : _List_fromArray(
		['Email Address is invalid']);
};
var $author$project$Main$emailAddressValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$Main$emailAddressToErrors);
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {fy: index, fB: match, fH: number, fV: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$never = _Regex_never;
var $rtfeldman$elm_validate$Validate$validEmail = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	A2(
		$elm$regex$Regex$fromStringWith,
		{e9: true, fF: false},
		'^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'));
var $rtfeldman$elm_validate$Validate$isValidEmail = function (email) {
	return A2($elm$regex$Regex$contains, $rtfeldman$elm_validate$Validate$validEmail, email);
};
var $rtfeldman$elm_validate$Validate$ifInvalidEmail = F2(
	function (subjectToEmail, errorFromEmail) {
		var getErrors = function (subject) {
			var email = subjectToEmail(subject);
			return $rtfeldman$elm_validate$Validate$isValidEmail(email) ? _List_Nil : _List_fromArray(
				[
					errorFromEmail(email)
				]);
		};
		return getErrors;
	});
var $author$project$Main$phoneNumToErrors = function (model) {
	return (!$elm$core$String$length(model.bo)) ? _List_Nil : (model.cC ? _List_Nil : _List_fromArray(
		['Phone number is invalid']));
};
var $author$project$Main$phoneNumValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$Main$phoneNumToErrors);
var $author$project$Main$postalCodeToErrors = function (model) {
	var length = $elm$core$String$length(model.ae);
	return (length < 5) ? _List_fromArray(
		['ZIP code is too short.']) : ((length > 9) ? _List_fromArray(
		['ZIP code is too long.']) : _List_Nil);
};
var $author$project$Main$postalCodeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$Main$postalCodeToErrors);
var $author$project$Main$piiValidator = $rtfeldman$elm_validate$Validate$firstError(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifInvalidEmail,
			function ($) {
				return $.aM;
			},
			function (_v0) {
				return 'Please enter a valid email address.';
			}),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aa;
			},
			'First name is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ab;
			},
			'Last name is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ah;
			},
			'Address 1 is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aj;
			},
			'City is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aA;
			},
			'State is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ae;
			},
			'Postal Code is missing.'),
			$author$project$Main$postalCodeValidator,
			$author$project$Main$phoneNumValidator,
			$author$project$Main$emailAddressValidator
		]));
var $author$project$Main$indInfoAndEmployer = $rtfeldman$elm_validate$Validate$firstError(
	_List_fromArray(
		[$author$project$Main$piiValidator, $author$project$Main$familyValidator, $author$project$Main$employmentStatusValidator, $author$project$Main$employerValidator]));
var $author$project$Main$indInfoValidator = $rtfeldman$elm_validate$Validate$firstError(
	_List_fromArray(
		[$author$project$Main$piiValidator, $author$project$Main$familyValidator, $author$project$Main$employmentStatusValidator]));
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $rtfeldman$elm_validate$Validate$ifEmptyList = F2(
	function (subjectToList, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return $elm$core$List$isEmpty(
					subjectToList(subject));
			},
			error);
	});
var $author$project$EntityType$isLLCorLLP = function (contributorType) {
	_v0$2:
	while (true) {
		if (!contributorType.$) {
			switch (contributorType.a) {
				case 9:
					var _v1 = contributorType.a;
					return true;
				case 3:
					var _v2 = contributorType.a;
					return true;
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return false;
};
var $author$project$Main$isLLCorLLPDonor = function (model) {
	return $author$project$EntityType$isLLCorLLP(model.D);
};
var $author$project$Main$organizationValidator = $rtfeldman$elm_validate$Validate$firstError(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifNothing,
			function ($) {
				return $.D;
			},
			'Please specify your organization classification.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $._;
			},
			'Please specify your organization name.')
		]));
var $author$project$Main$ownersPercentageToErrors = function (model) {
	var totalPercentage = $author$project$Owners$foldOwnership(model.m);
	return (totalPercentage !== 100) ? _List_fromArray(
		['Total Percent ownership must equal 100%']) : _List_Nil;
};
var $author$project$Main$ownersPercentageValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$Main$ownersPercentageToErrors);
var $author$project$Main$orgInfoValidator = function (model) {
	var extra = $author$project$Main$isLLCorLLPDonor(model) ? _List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifEmptyList,
			function ($) {
				return $.m;
			},
			'Please specify the ownership breakdown for your LLC.'),
			$author$project$Main$ownersPercentageValidator
		]) : _List_Nil;
	return $rtfeldman$elm_validate$Validate$firstError(
		_Utils_ap(
			_List_fromArray(
				[$author$project$Main$organizationValidator, $author$project$Main$piiValidator]),
			extra));
};
var $author$project$Main$paymentInfoValidator = $rtfeldman$elm_validate$Validate$all(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aK;
			},
			'Please specify your card number.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ap;
			},
			'Please specify the expiration month.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aq;
			},
			'Please specify the expiration year.')
		]));
var $author$project$Copy$paymentProcessingFailure = 'Payment could not be processed. Please ensure payment details are correct.';
var $author$project$Main$GotAPIResponseContribute = function (a) {
	return {$: 40, a: a};
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$round = _Basics_round;
var $author$project$Main$dollarStringToCents = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toFloat,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$withDefault(0),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Basics$mul(100),
			$elm$core$Basics$round)));
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$EntityType$LimitedLiabilityCompany = 9;
var $author$project$EntityType$llc = 9;
var $author$project$Main$numberStringToInt = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toInt,
	$elm$core$Maybe$withDefault(0));
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Owners$encoder = function (owner) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'firstName',
				$elm$json$Json$Encode$string(owner.aa)),
				_Utils_Tuple2(
				'lastName',
				$elm$json$Json$Encode$string(owner.ab)),
				_Utils_Tuple2(
				'addressLine1',
				$elm$json$Json$Encode$string(owner.ah)),
				_Utils_Tuple2(
				'addressLine2',
				$elm$json$Json$Encode$string(owner.aG)),
				_Utils_Tuple2(
				'city',
				$elm$json$Json$Encode$string(owner.aj)),
				_Utils_Tuple2(
				'state',
				$elm$json$Json$Encode$string(owner.aA)),
				_Utils_Tuple2(
				'postalCode',
				$elm$json$Json$Encode$string(owner.ae)),
				_Utils_Tuple2(
				'percentOwnership',
				$elm$json$Json$Encode$string(owner.dB))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Main$optionalFieldOwners = F2(
	function (key, val) {
		return _Utils_eq(
			val,
			$elm$core$Maybe$Just(_List_Nil)) ? _List_Nil : _List_fromArray(
			[
				_Utils_Tuple2(
				key,
				A2(
					$elm$json$Json$Encode$list,
					$author$project$Owners$encoder,
					A2($elm$core$Maybe$withDefault, _List_Nil, val)))
			]);
	});
var $author$project$Main$optionalString = F2(
	function (key, val) {
		return (val === '') ? _List_Nil : _List_fromArray(
			[
				_Utils_Tuple2(
				key,
				$elm$json$Json$Encode$string(val))
			]);
	});
var $author$project$EntityType$toDataString = function (contributorType) {
	switch (contributorType) {
		case 0:
			return 'Fam';
		case 1:
			return 'Ind';
		case 2:
			return 'Solep';
		case 3:
			return 'Part';
		case 5:
			return 'Corp';
		case 6:
			return 'Comm';
		case 7:
			return 'Union';
		case 8:
			return 'Assoc';
		case 9:
			return 'Llc';
		case 10:
			return 'Pac';
		case 11:
			return 'Plc';
		case 12:
			return 'Oth';
		default:
			return 'Can';
	}
};
var $author$project$EmploymentStatus$toString = function (val) {
	if (!val.$) {
		switch (val.a) {
			case 0:
				var _v1 = val.a;
				return 'Employed';
			case 1:
				var _v2 = val.a;
				return 'SelfEmployed';
			case 2:
				var _v3 = val.a;
				return 'Retired';
			default:
				var _v4 = val.a;
				return 'Unemployed';
		}
	} else {
		return '';
	}
};
var $elm$core$String$toUpper = _String_toUpper;
var $author$project$Main$encodeContribution = function (model) {
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'committeeId',
					$elm$json$Json$Encode$string(model.a6)),
					_Utils_Tuple2(
					'amount',
					$elm$json$Json$Encode$int(
						$author$project$Main$dollarStringToCents(model.V))),
					_Utils_Tuple2(
					'firstName',
					$elm$json$Json$Encode$string(model.aa)),
					_Utils_Tuple2(
					'lastName',
					$elm$json$Json$Encode$string(model.ab)),
					_Utils_Tuple2(
					'addressLine1',
					$elm$json$Json$Encode$string(model.ah)),
					_Utils_Tuple2(
					'city',
					$elm$json$Json$Encode$string(model.aj)),
					_Utils_Tuple2(
					'state',
					$elm$json$Json$Encode$string(
						$elm$core$String$toUpper(model.aA))),
					_Utils_Tuple2(
					'postalCode',
					$elm$json$Json$Encode$string(model.ae)),
					_Utils_Tuple2(
					'entityType',
					$elm$json$Json$Encode$string(
						$author$project$EntityType$toDataString(
							A2($elm$core$Maybe$withDefault, $author$project$EntityType$llc, model.D)))),
					_Utils_Tuple2(
					'emailAddress',
					$elm$json$Json$Encode$string(model.aM)),
					_Utils_Tuple2(
					'cardNumber',
					$elm$json$Json$Encode$string(model.aK)),
					_Utils_Tuple2(
					'cardExpirationMonth',
					$elm$json$Json$Encode$int(
						$author$project$Main$numberStringToInt(model.ap))),
					_Utils_Tuple2(
					'cardExpirationYear',
					$elm$json$Json$Encode$int(
						$author$project$Main$numberStringToInt(model.aq))),
					_Utils_Tuple2(
					'cardCVC',
					$elm$json$Json$Encode$string(model.a7)),
					_Utils_Tuple2(
					'attestsToBeingAnAdultCitizen',
					$elm$json$Json$Encode$bool(model.ai))
				]),
			_Utils_ap(
				A2(
					$author$project$Main$optionalString,
					'employmentStatus',
					$author$project$EmploymentStatus$toString(model.J)),
				_Utils_ap(
					A2($author$project$Main$optionalString, 'entityName', model._),
					_Utils_ap(
						A2($author$project$Main$optionalString, 'employer', model.aN),
						_Utils_ap(
							A2($author$project$Main$optionalString, 'occupation', model.aT),
							_Utils_ap(
								A2($author$project$Main$optionalString, 'refCode', model.dG),
								_Utils_ap(
									A2($author$project$Main$optionalString, 'addressLine2', model.aG),
									A2(
										$author$project$Main$optionalFieldOwners,
										'owners',
										$elm$core$Maybe$Just(model.m))))))))));
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $jzxhuang$http_extras$Http$Detailed$BadBody = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $jzxhuang$http_extras$Http$Detailed$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $jzxhuang$http_extras$Http$Detailed$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $jzxhuang$http_extras$Http$Detailed$NetworkError = {$: 2};
var $jzxhuang$http_extras$Http$Detailed$Timeout = {$: 1};
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $jzxhuang$http_extras$Http$Detailed$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$jzxhuang$http_extras$Http$Detailed$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($jzxhuang$http_extras$Http$Detailed$Timeout);
			case 2:
				return $elm$core$Result$Err($jzxhuang$http_extras$Http$Detailed$NetworkError);
			case 3:
				var metadata = response.a;
				var body = response.b;
				return $elm$core$Result$Err(
					A2($jzxhuang$http_extras$Http$Detailed$BadStatus, metadata, body));
			default:
				var metadata = response.a;
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					A2($jzxhuang$http_extras$Http$Detailed$BadBody, metadata, body),
					toResult(
						_Utils_Tuple2(metadata, body)));
		}
	});
var $jzxhuang$http_extras$Http$Detailed$responseToString = function (responseString) {
	return A2(
		$jzxhuang$http_extras$Http$Detailed$resolve,
		function (_v0) {
			var metadata = _v0.a;
			var body = _v0.b;
			return $elm$core$Result$Ok(
				_Utils_Tuple2(metadata, body));
		},
		responseString);
};
var $jzxhuang$http_extras$Http$Detailed$expectString = function (toMsg) {
	return A2($elm$http$Http$expectStringResponse, toMsg, $jzxhuang$http_extras$Http$Detailed$responseToString);
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {eJ: reqs, eR: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.eZ;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.eJ));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.eR)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					e6: r.e6,
					bQ: r.bQ,
					fn: A2(_Http_mapExpect, func, r.fn),
					ed: r.ed,
					fD: r.fD,
					fY: r.fY,
					eZ: r.eZ,
					f_: r.f_
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{e6: false, bQ: r.bQ, fn: r.fn, ed: r.ed, fD: r.fD, fY: r.fY, eZ: r.eZ, f_: r.f_}));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{bQ: r.bQ, fn: r.fn, ed: _List_Nil, fD: 'POST', fY: $elm$core$Maybe$Nothing, eZ: $elm$core$Maybe$Nothing, f_: r.f_});
};
var $author$project$Main$postContribution = function (model) {
	return $elm$http$Http$post(
		{
			bQ: $elm$http$Http$jsonBody(
				$author$project$Main$encodeContribution(model)),
			fn: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Main$GotAPIResponseContribute),
			f_: model.dj
		});
};
var $author$project$Main$NoOp = {$: 41};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$Main$scrollUp = A2(
	$elm$core$Task$attempt,
	function (_v0) {
		return $author$project$Main$NoOp;
	},
	A2(
		$elm$core$Task$onError,
		function (_v1) {
			return $elm$core$Task$succeed(0);
		},
		A2($elm$browser$Browser$Dom$setViewport, 0, 0)));
var $author$project$Main$sendEmail = _Platform_outgoingPort('sendEmail', $elm$json$Json$Encode$string);
var $author$project$Main$sendNumber = _Platform_outgoingPort('sendNumber', $elm$json$Json$Encode$string);
var $author$project$Owners$toHash = function (owner) {
	return owner.aa + ('-' + (owner.ab + ('-' + (owner.ah + ('-' + (owner.aj + ('-' + (owner.aA + ('-' + owner.ae)))))))));
};
var $author$project$Main$toggleDisplayState = F2(
	function (model, formView) {
		var paymentDetailsToggled = (!model.N) ? 0 : 2;
		switch (formView) {
			case 0:
				return _Utils_update(
					model,
					{H: 1, I: 2, b: _List_Nil, N: paymentDetailsToggled, ay: $elm$core$Maybe$Nothing, n: false});
			case 1:
				return _Utils_update(
					model,
					{H: 2, I: 1, b: _List_Nil, N: paymentDetailsToggled, ay: $elm$core$Maybe$Nothing, n: false});
			default:
				return _Utils_update(
					model,
					{H: 2, I: 2, b: _List_Nil, N: 1, n: false});
		}
	});
var $rtfeldman$elm_validate$Validate$Valid = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$validate = F2(
	function (_v0, subject) {
		var getErrors = _v0;
		var _v1 = getErrors(subject);
		if (!_v1.b) {
			return $elm$core$Result$Ok(subject);
		} else {
			var errors = _v1;
			return $elm$core$Result$Err(errors);
		}
	});
var $rtfeldman$elm_validate$Validate$ifFalse = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_Nil : _List_fromArray(
				[error]);
		};
		return getErrors;
	});
var $rtfeldman$elm_validate$Validate$isFloat = function (str) {
	var _v0 = $elm$core$String$toFloat(str);
	if (!_v0.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Owners$ifNotFloat = F2(
	function (subjectToString, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			function (subject) {
				return $rtfeldman$elm_validate$Validate$isFloat(
					subjectToString(subject));
			},
			error);
	});
var $author$project$Owners$validator = $rtfeldman$elm_validate$Validate$firstError(
	_List_fromArray(
		[
			A2(
			$author$project$Owners$ifNotFloat,
			function ($) {
				return $.dB;
			},
			'Ownership percentage must be a valid a number.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aa;
			},
			'Owner First name is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ab;
			},
			'Owner Last name is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ah;
			},
			'Owner Address 1 is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aj;
			},
			'Owner City is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.aA;
			},
			'Owner State is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ae;
			},
			'Owner Postal Code is missing.')
		]));
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 35:
				return _Utils_Tuple2(
					A2($author$project$Main$toggleDisplayState, model, 0),
					$elm$core$Platform$Cmd$none);
			case 36:
				return _Utils_Tuple2(
					A2($author$project$Main$toggleDisplayState, model, 1),
					$elm$core$Platform$Cmd$none);
			case 37:
				return _Utils_Tuple2(
					A2($author$project$Main$toggleDisplayState, model, 2),
					$elm$core$Platform$Cmd$none);
			case 0:
				var amount = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{V: amount, b: _List_Nil}),
					$elm$core$Platform$Cmd$none);
			case 32:
				var _v1 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Main$amountValidator, model);
				if (_v1.$ === 1) {
					var messages = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: messages}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{H: 2, I: 1, b: _List_Nil}),
						$author$project$Main$scrollUp);
				}
			case 33:
				var validator = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						if (!_v3.a) {
							var _v4 = _v3.a;
							return $author$project$Main$orgInfoValidator(model);
						} else {
							var _v5 = _v3.a;
							var _v6 = model.J;
							_v6$2:
							while (true) {
								if (!_v6.$) {
									switch (_v6.a) {
										case 0:
											var _v7 = _v6.a;
											return $author$project$Main$indInfoAndEmployer;
										case 1:
											var _v8 = _v6.a;
											return $author$project$Main$indInfoAndEmployer;
										default:
											break _v6$2;
									}
								} else {
									break _v6$2;
								}
							}
							return $author$project$Main$indInfoValidator;
						}
					} else {
						return $author$project$Main$indInfoValidator;
					}
				}();
				var _v2 = A2($rtfeldman$elm_validate$Validate$validate, validator, model);
				if (_v2.$ === 1) {
					var messages = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: messages}),
						$author$project$Main$scrollUp);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{H: 2, I: 2, b: _List_Nil, N: 1}),
						$author$project$Main$scrollUp);
				}
			case 1:
				var maybeOrgOrInd = msg.a;
				var employmentStatus = function () {
					if (!maybeOrgOrInd.$) {
						if (!maybeOrgOrInd.a) {
							var _v10 = maybeOrgOrInd.a;
							return $elm$core$Maybe$Just(0);
						} else {
							var _v11 = maybeOrgOrInd.a;
							return $elm$core$Maybe$Nothing;
						}
					} else {
						return model.J;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: employmentStatus, b: _List_Nil, D: $elm$core$Maybe$Nothing, av: maybeOrgOrInd, n: false}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var entityName = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{_: entityName, n: false}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var maybeEntityType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: maybeEntityType, n: false}),
					$elm$core$Platform$Cmd$none);
			case 38:
				var newOwner = msg.a;
				var withoutOwner = A2(
					$elm$core$List$filter,
					function (owner) {
						return !_Utils_eq(
							$author$project$Owners$toHash(owner),
							$author$project$Owners$toHash(newOwner));
					},
					model.m);
				var withNewOwner = _Utils_ap(
					withoutOwner,
					_List_fromArray(
						[newOwner]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m: withNewOwner}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var deletedOwner = msg.a;
				var newOwners = A2(
					$elm$core$List$filter,
					function (owner) {
						return !_Utils_eq(
							$author$project$Owners$toHash(owner),
							$author$project$Owners$toHash(deletedOwner));
					},
					model.m);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m: newOwners}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var newOwner = {ah: model.aU, aG: model.aV, aj: model.aW, aa: model.aX, ab: model.aY, dB: model.aZ, ae: model.a_, aA: model.a$};
				var _v12 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Owners$validator, newOwner);
				if (_v12.$ === 1) {
					var messages = _v12.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: messages}),
						$author$project$Main$scrollUp);
				} else {
					var totalPercentage = $author$project$Owners$foldOwnership(model.m) + A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toFloat(newOwner.dB));
					return (totalPercentage > 100) ? _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b: _List_fromArray(
									['Total percentage exceeds 100.'])
							}),
						$author$project$Main$scrollUp) : _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b: _List_Nil,
								aU: '',
								aV: '',
								aW: '',
								aX: '',
								aY: '',
								aZ: '',
								a_: '',
								a$: '',
								m: _Utils_ap(
									model.m,
									_List_fromArray(
										[newOwner]))
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 19:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: str}),
					$elm$core$Platform$Cmd$none);
			case 20:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aY: str}),
					$elm$core$Platform$Cmd$none);
			case 21:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aU: str}),
					$elm$core$Platform$Cmd$none);
			case 22:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aV: str}),
					$elm$core$Platform$Cmd$none);
			case 23:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aW: str}),
					$elm$core$Platform$Cmd$none);
			case 24:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a$: str}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a_: str}),
					$elm$core$Platform$Cmd$none);
			case 26:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aZ: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bo: str}),
					$author$project$Main$sendNumber(str));
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: str}),
					$author$project$Main$sendEmail(str));
			case 4:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aa: str}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ab: str}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ah: str}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aG: str}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ae: str}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aj: str}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: str}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var contributorType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: $elm$core$Maybe$Just(contributorType)
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var val = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							J: $elm$core$Maybe$Just(val)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aN: str}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aT: str}),
					$elm$core$Platform$Cmd$none);
			case 39:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: bool}),
					$elm$core$Platform$Cmd$none);
			case 42:
				var str = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 27:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aK: str}),
					$elm$core$Platform$Cmd$none);
			case 28:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ap: str}),
					$elm$core$Platform$Cmd$none);
			case 29:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aq: str}),
					$elm$core$Platform$Cmd$none);
			case 30:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ap: str, aq: str}),
					$elm$core$Platform$Cmd$none);
			case 31:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a7: str, n: false}),
					$elm$core$Platform$Cmd$none);
			case 34:
				var _v13 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Main$paymentInfoValidator, model);
				if (_v13.$ === 1) {
					var messages = _v13.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: messages}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: _List_Nil, cB: true, O: true}),
						$author$project$Main$postContribution(model));
				}
			case 40:
				var res = msg.a;
				if (!res.$) {
					var _v15 = res.a;
					var metadata = _v15.a;
					var str = _v15.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: _List_Nil, cX: true}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = res.a;
					switch (error.$) {
						case 4:
							var metadata = error.a;
							var body = error.b;
							var str = error.c;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: _List_fromArray(
											[$author$project$Copy$genericError])
									}),
								$elm$core$Platform$Cmd$none);
						case 3:
							var metadata = error.a;
							var body = error.b;
							var errorMsg = function () {
								var _v19 = A2(
									$elm$json$Json$Decode$decodeString,
									A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
									body);
								if (!_v19.$) {
									var value = _v19.a;
									return value;
								} else {
									var err = _v19.a;
									return $author$project$Copy$genericError;
								}
							}();
							var _v17 = metadata.fT;
							if (_v17 === 422) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											b: _List_fromArray(
												[$author$project$Copy$paymentProcessingFailure]),
											O: false
										}),
									$elm$core$Platform$Cmd$none);
							} else {
								var remainingRes = A2(
									$elm$json$Json$Decode$decodeString,
									A2($elm$json$Json$Decode$field, 'remaining', $elm$json$Json$Decode$int),
									body);
								if (remainingRes.$ === 1) {
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												b: _List_fromArray(
													[errorMsg]),
												O: false
											}),
										$elm$core$Platform$Cmd$none);
								} else {
									var remaining = remainingRes.a;
									return (remaining > 0) ? _Utils_Tuple2(
										_Utils_update(
											model,
											{
												H: 1,
												I: 2,
												b: _List_fromArray(
													[
														$author$project$Copy$currentDonationExceedsLimit(remaining)
													]),
												N: 2,
												ay: $elm$core$Maybe$Just(remaining),
												n: true,
												O: false
											}),
										$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
										_Utils_update(
											model,
											{
												H: 2,
												I: 2,
												b: _List_fromArray(
													[errorMsg]),
												N: 2,
												ay: $elm$core$Maybe$Just(remaining),
												n: true,
												O: false
											}),
										$elm$core$Platform$Cmd$none);
								}
							}
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: _List_fromArray(
											[$author$project$Copy$genericError]),
										O: false
									}),
								$elm$core$Platform$Cmd$none);
					}
				}
			case 43:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bT: bool}),
					$elm$core$Platform$Cmd$none);
			case 44:
				var value = msg.a;
				var _v20 = A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$bool, value);
				if (!_v20.$) {
					var data = _v20.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cC: data}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = _v20.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 45:
				var value = msg.a;
				var _v21 = A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$bool, value);
				if (!_v21.$) {
					var data = _v21.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b3: data}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = _v21.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Main$SubmitPaymentInfo = {$: 34};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Block = {$: 2};
var $rundis$elm_bootstrap$Bootstrap$Button$block = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Block;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size = modifier.a;
				return _Utils_update(
					options,
					{
						eQ: $elm$core$Maybe$Just(size)
					});
			case 1:
				var coloring = modifier.a;
				return _Utils_update(
					options,
					{
						X: $elm$core$Maybe$Just(coloring)
					});
			case 2:
				return _Utils_update(
					options,
					{bP: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{b2: val});
			default:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						bM: _Utils_ap(options.bM, attrs)
					});
		}
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions = {bM: _List_Nil, bP: false, X: $elm$core$Maybe$Nothing, b2: false, eQ: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass = function (role) {
	switch (role) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary';
		case 2:
			return 'success';
		case 3:
			return 'info';
		case 4:
			return 'warning';
		case 5:
			return 'danger';
		case 6:
			return 'dark';
		case 7:
			return 'light';
		default:
			return 'link';
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('sm');
		case 2:
			return $elm$core$Maybe$Just('md');
		case 3:
			return $elm$core$Maybe$Just('lg');
		default:
			return $elm$core$Maybe$Just('xl');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier, $rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('btn', true),
						_Utils_Tuple2('btn-block', options.bP),
						_Utils_Tuple2('disabled', options.b2)
					])),
				$elm$html$Html$Attributes$disabled(options.b2)
			]),
		_Utils_ap(
			function () {
				var _v0 = A2($elm$core$Maybe$andThen, $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption, options.eQ);
				if (!_v0.$) {
					var s = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn-' + s)
						]);
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.X;
					if (!_v1.$) {
						if (!_v1.a.$) {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'btn-' + $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						} else {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'btn-outline-' + $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						}
					} else {
						return _List_Nil;
					}
				}(),
				options.bM)));
};
var $rundis$elm_bootstrap$Bootstrap$Button$button = F2(
	function (options, children) {
		return A2(
			$elm$html$Html$button,
			$rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled(disabled_);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $rundis$elm_bootstrap$Bootstrap$Button$onClick = function (message) {
	return $rundis$elm_bootstrap$Bootstrap$Button$attrs(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$Events$preventDefaultOn,
				'click',
				$elm$json$Json$Decode$succeed(
					_Utils_Tuple2(message, true)))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p2 = $elm$html$Html$Attributes$class('p-2');
var $rundis$elm_bootstrap$Bootstrap$Spinner$Size = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$Small = 1;
var $rundis$elm_bootstrap$Bootstrap$Spinner$small = $rundis$elm_bootstrap$Bootstrap$Spinner$Size(1);
var $rundis$elm_bootstrap$Bootstrap$Spinner$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var spinnerKind = modifier.a;
				return _Utils_update(
					options,
					{bg: spinnerKind});
			case 1:
				var spinnerSize = modifier.a;
				return _Utils_update(
					options,
					{eQ: spinnerSize});
			case 2:
				var color_ = modifier.a;
				return _Utils_update(
					options,
					{
						b_: $elm$core$Maybe$Just(color_)
					});
			default:
				var list = modifier.a;
				return _Utils_update(
					options,
					{bM: list});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Spinner$Border = 0;
var $rundis$elm_bootstrap$Bootstrap$Spinner$Normal = 0;
var $rundis$elm_bootstrap$Bootstrap$Spinner$defaultOptions = {bM: _List_Nil, b_: $elm$core$Maybe$Nothing, bg: 0, eQ: 0};
var $rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName = function (kind_) {
	if (!kind_) {
		return 'spinner-border';
	} else {
		return 'spinner-grow';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$kindClass = A2($elm$core$Basics$composeL, $elm$html$Html$Attributes$class, $rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName);
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $rundis$elm_bootstrap$Bootstrap$Spinner$sizeAttributes = F2(
	function (size_, kind_) {
		switch (size_) {
			case 0:
				return $elm$core$Maybe$Nothing;
			case 1:
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							$rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName(kind_) + '-sm')
						]));
			default:
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '3rem'),
							A2($elm$html$Html$Attributes$style, 'height', '3rem')
						]));
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass = F2(
	function (prefix, role) {
		return $elm$html$Html$Attributes$class(
			prefix + ('-' + function () {
				switch (role) {
					case 0:
						return 'primary';
					case 1:
						return 'secondary';
					case 2:
						return 'success';
					case 3:
						return 'info';
					case 4:
						return 'warning';
					case 5:
						return 'danger';
					case 6:
						return 'light';
					default:
						return 'dark';
				}
			}()));
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textColorClass = function (color) {
	if (color.$ === 1) {
		return $elm$html$Html$Attributes$class('text-white');
	} else {
		var role = color.a;
		return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'text', role);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$toAttributes = function (options) {
	return _Utils_ap(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$core$Maybe$Just(
					$rundis$elm_bootstrap$Bootstrap$Spinner$kindClass(options.bg)),
					A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Internal$Text$textColorClass, options.b_)
				])),
		_Utils_ap(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($rundis$elm_bootstrap$Bootstrap$Spinner$sizeAttributes, options.eQ, options.bg)),
			_Utils_ap(
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'status')
					]),
				options.bM)));
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$spinner = F2(
	function (options, children) {
		var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Spinner$applyModifier, $rundis$elm_bootstrap$Bootstrap$Spinner$defaultOptions, options);
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Spinner$toAttributes(opts),
			children);
	});
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $rundis$elm_bootstrap$Bootstrap$Spinner$srMessage = function (msg) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sr-only')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(msg)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Success = 2;
var $rundis$elm_bootstrap$Bootstrap$Button$success = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(2));
var $author$project$SubmitButton$submitButton = F5(
	function (msg, buttonText, loading, enabled, testAttr) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$success,
					$rundis$elm_bootstrap$Bootstrap$Button$block,
					$rundis$elm_bootstrap$Bootstrap$Button$onClick(msg),
					$rundis$elm_bootstrap$Bootstrap$Button$disabled(!enabled),
					$rundis$elm_bootstrap$Bootstrap$Button$attrs(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p2,
							A2($elm$html$Html$Attributes$attribute, 'data-cy', testAttr)
						]))
				]),
			_List_fromArray(
				[
					loading ? A2(
					$rundis$elm_bootstrap$Bootstrap$Spinner$spinner,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Spinner$small]),
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Spinner$srMessage('Loading...')
						])) : $elm$html$Html$text(buttonText)
				]));
	});
var $author$project$Main$donateButton = function (model) {
	return A5($author$project$SubmitButton$submitButton, $author$project$Main$SubmitPaymentInfo, 'Donate!', model.O, !model.O, 'donateBtn');
};
var $author$project$Main$AmountUpdated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$OpenDonationAmount = {$: 35};
var $author$project$Main$SubmitAmount = {$: 32};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Column = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$col = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Column(
			{d_: children, dx: options});
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$containerFluid = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container-fluid')
					]),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width = F2(
	function (screenSize, columnCount) {
		return {d$: columnCount, eP: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$General$Internal$XS = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign = F2(
	function (align_, options) {
		var _v0 = align_.eP;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						bJ: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bH: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bG: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bF: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						bI: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset = F2(
	function (offset_, options) {
		var _v0 = offset_.eP;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						co: $elm$core$Maybe$Just(offset_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						cl: $elm$core$Maybe$Just(offset_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						ck: $elm$core$Maybe$Just(offset_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						cj: $elm$core$Maybe$Just(offset_)
					});
			default:
				return _Utils_update(
					options,
					{
						cn: $elm$core$Maybe$Just(offset_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder = F2(
	function (order_, options) {
		var _v0 = order_.eP;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						cz: $elm$core$Maybe$Just(order_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						cx: $elm$core$Maybe$Just(order_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						cv: $elm$core$Maybe$Just(order_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						cu: $elm$core$Maybe$Just(order_)
					});
			default:
				return _Utils_update(
					options,
					{
						cy: $elm$core$Maybe$Just(order_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull = F2(
	function (pull_, options) {
		var _v0 = pull_.eP;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						cI: $elm$core$Maybe$Just(pull_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						cG: $elm$core$Maybe$Just(pull_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						cF: $elm$core$Maybe$Just(pull_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						cE: $elm$core$Maybe$Just(pull_)
					});
			default:
				return _Utils_update(
					options,
					{
						cH: $elm$core$Maybe$Just(pull_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush = F2(
	function (push_, options) {
		var _v0 = push_.eP;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						cN: $elm$core$Maybe$Just(push_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						cL: $elm$core$Maybe$Just(push_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						cK: $elm$core$Maybe$Just(push_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						cJ: $elm$core$Maybe$Just(push_)
					});
			default:
				return _Utils_update(
					options,
					{
						cM: $elm$core$Maybe$Just(push_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth = F2(
	function (width_, options) {
		var _v0 = width_.eP;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						bA: $elm$core$Maybe$Just(width_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						by: $elm$core$Maybe$Just(width_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bx: $elm$core$Maybe$Just(width_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bw: $elm$core$Maybe$Just(width_)
					});
			default:
				return _Utils_update(
					options,
					{
						bz: $elm$core$Maybe$Just(width_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 6:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						bM: _Utils_ap(options.bM, attrs)
					});
			case 0:
				var width_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth, width_, options);
			case 1:
				var offset_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset, offset_, options);
			case 2:
				var pull_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull, pull_, options);
			case 3:
				var push_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush, push_, options);
			case 4:
				var order_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder, order_, options);
			case 5:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign, align, options);
			default:
				var align = modifier.a;
				return _Utils_update(
					options,
					{
						cY: $elm$core$Maybe$Just(align)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('1');
		case 2:
			return $elm$core$Maybe$Just('2');
		case 3:
			return $elm$core$Maybe$Just('3');
		case 4:
			return $elm$core$Maybe$Just('4');
		case 5:
			return $elm$core$Maybe$Just('5');
		case 6:
			return $elm$core$Maybe$Just('6');
		case 7:
			return $elm$core$Maybe$Just('7');
		case 8:
			return $elm$core$Maybe$Just('8');
		case 9:
			return $elm$core$Maybe$Just('9');
		case 10:
			return $elm$core$Maybe$Just('10');
		case 11:
			return $elm$core$Maybe$Just('11');
		case 12:
			return $elm$core$Maybe$Just('12');
		default:
			return $elm$core$Maybe$Just('auto');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass = function (_v0) {
	var screenSize = _v0.eP;
	var columnCount = _v0.d$;
	return $elm$html$Html$Attributes$class(
		'col' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption(columnCount)))));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes = function (widths) {
	var width_ = function (w) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass, w);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, width_, widths));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions = {bF: $elm$core$Maybe$Nothing, bG: $elm$core$Maybe$Nothing, bH: $elm$core$Maybe$Nothing, bI: $elm$core$Maybe$Nothing, bJ: $elm$core$Maybe$Nothing, bM: _List_Nil, cj: $elm$core$Maybe$Nothing, ck: $elm$core$Maybe$Nothing, cl: $elm$core$Maybe$Nothing, cn: $elm$core$Maybe$Nothing, co: $elm$core$Maybe$Nothing, cu: $elm$core$Maybe$Nothing, cv: $elm$core$Maybe$Nothing, cx: $elm$core$Maybe$Nothing, cy: $elm$core$Maybe$Nothing, cz: $elm$core$Maybe$Nothing, cE: $elm$core$Maybe$Nothing, cF: $elm$core$Maybe$Nothing, cG: $elm$core$Maybe$Nothing, cH: $elm$core$Maybe$Nothing, cI: $elm$core$Maybe$Nothing, cJ: $elm$core$Maybe$Nothing, cK: $elm$core$Maybe$Nothing, cL: $elm$core$Maybe$Nothing, cM: $elm$core$Maybe$Nothing, cN: $elm$core$Maybe$Nothing, cY: $elm$core$Maybe$Nothing, bw: $elm$core$Maybe$Nothing, bx: $elm$core$Maybe$Nothing, by: $elm$core$Maybe$Nothing, bz: $elm$core$Maybe$Nothing, bA: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		default:
			return '11';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString = function (screenSize) {
	var _v0 = $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize);
	if (!_v0.$) {
		var s = _v0.a;
		return '-' + (s + '-');
	} else {
		return '-';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass = function (_v0) {
	var screenSize = _v0.eP;
	var offsetCount = _v0.ex;
	return $elm$html$Html$Attributes$class(
		'offset' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption(offsetCount)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes = function (offsets) {
	var offset_ = function (m) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass, m);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, offset_, offsets));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption = function (size) {
	switch (size) {
		case 0:
			return 'first';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		case 12:
			return '12';
		default:
			return 'last';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes = function (orders) {
	var order_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.eP;
			var moveCount = m.a.aS;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'order' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, order_, orders));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		default:
			return '12';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes = function (pulls) {
	var pull_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.eP;
			var moveCount = m.a.aS;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'pull' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, pull_, pulls));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes = function (pushes) {
	var push_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.eP;
			var moveCount = m.a.aS;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'push' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, push_, pushes));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption = function (dir) {
	switch (dir) {
		case 1:
			return 'center';
		case 0:
			return 'left';
		default:
			return 'right';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass = function (_v0) {
	var dir = _v0.d3;
	var size = _v0.eQ;
	return $elm$html$Html$Attributes$class(
		'text' + (A2(
			$elm$core$Maybe$withDefault,
			'-',
			A2(
				$elm$core$Maybe$map,
				function (s) {
					return '-' + (s + '-');
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size))) + $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption(dir)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		default:
			return 'end';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass = F2(
	function (prefix, _v0) {
		var align = _v0.dV;
		var screenSize = _v0.eP;
		return $elm$html$Html$Attributes$class(
			_Utils_ap(
				prefix,
				_Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return v + '-';
							},
							$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))),
					$rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption(align))));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes = F2(
	function (prefix, aligns) {
		var align = function (a) {
			return A2(
				$elm$core$Maybe$map,
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass(prefix),
				a);
		};
		return A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, align, aligns));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions, modifiers);
	var shouldAddDefaultXs = !$elm$core$List$length(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[options.bA, options.by, options.bx, options.bw, options.bz])));
	return _Utils_ap(
		$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes(
			_List_fromArray(
				[
					shouldAddDefaultXs ? $elm$core$Maybe$Just(
					A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, 0, 0)) : options.bA,
					options.by,
					options.bx,
					options.bw,
					options.bz
				])),
		_Utils_ap(
			$rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes(
				_List_fromArray(
					[options.co, options.cl, options.ck, options.cj, options.cn])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes(
					_List_fromArray(
						[options.cI, options.cG, options.cF, options.cE, options.cH])),
				_Utils_ap(
					$rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes(
						_List_fromArray(
							[options.cN, options.cL, options.cK, options.cJ, options.cM])),
					_Utils_ap(
						$rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes(
							_List_fromArray(
								[options.cz, options.cx, options.cv, options.cu, options.cy])),
						_Utils_ap(
							A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
								'align-self-',
								_List_fromArray(
									[options.bJ, options.bH, options.bG, options.bF, options.bI])),
							_Utils_ap(
								function () {
									var _v0 = options.cY;
									if (!_v0.$) {
										var a = _v0.a;
										return _List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(a)
											]);
									} else {
										return _List_Nil;
									}
								}(),
								options.bM)))))));
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $rundis$elm_bootstrap$Bootstrap$Grid$renderCol = function (column) {
	switch (column.$) {
		case 0:
			var options = column.a.dx;
			var children = column.a.d_;
			return A2(
				$elm$html$Html$div,
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
		case 1:
			var e = column.a;
			return e;
		default:
			var options = column.a.dx;
			var children = column.a.d_;
			return A3(
				$elm$html$Html$Keyed$node,
				'div',
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign = F2(
	function (align, options) {
		var _v0 = align.eP;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						b9: $elm$core$Maybe$Just(align)
					});
			case 1:
				return _Utils_update(
					options,
					{
						b7: $elm$core$Maybe$Just(align)
					});
			case 2:
				return _Utils_update(
					options,
					{
						b6: $elm$core$Maybe$Just(align)
					});
			case 3:
				return _Utils_update(
					options,
					{
						b5: $elm$core$Maybe$Just(align)
					});
			default:
				return _Utils_update(
					options,
					{
						b8: $elm$core$Maybe$Just(align)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign = F2(
	function (align_, options) {
		var _v0 = align_.eP;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						c5: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						c3: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						c2: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						c1: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						c4: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 2:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						bM: _Utils_ap(options.bM, attrs)
					});
			case 0:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign, align, options);
			default:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign, align, options);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions = {bM: _List_Nil, b5: $elm$core$Maybe$Nothing, b6: $elm$core$Maybe$Nothing, b7: $elm$core$Maybe$Nothing, b8: $elm$core$Maybe$Nothing, b9: $elm$core$Maybe$Nothing, c1: $elm$core$Maybe$Nothing, c2: $elm$core$Maybe$Nothing, c3: $elm$core$Maybe$Nothing, c4: $elm$core$Maybe$Nothing, c5: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		case 2:
			return 'end';
		case 3:
			return 'around';
		default:
			return 'between';
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass = function (_v0) {
	var align = _v0.dV;
	var screenSize = _v0.eP;
	return $elm$html$Html$Attributes$class(
		'justify-content-' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return v + '-';
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + $rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption(align)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes = function (aligns) {
	var align = function (a) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass, a);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, align, aligns));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		_Utils_ap(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
				'align-items-',
				_List_fromArray(
					[options.c5, options.c3, options.c2, options.c1, options.c4])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes(
					_List_fromArray(
						[options.b9, options.b7, options.b6, options.b5, options.b8])),
				options.bM)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$row = F2(
	function (options, cols) {
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Grid$renderCol, cols));
	});
var $author$project$Main$errorMessages = function (errors) {
	return (!$elm$core$List$length(errors)) ? _List_Nil : _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							A2(
								$elm$core$List$map,
								function (error) {
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-danger list-unstyled')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(error)
											]));
								},
								errors))
						]))
				]))
		]);
};
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb3 = $elm$html$Html$Attributes$class('pb-3');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pt3 = $elm$html$Html$Attributes$class('pt-3');
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col9 = 9;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$width = F2(
	function (size, count) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth(
			A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, size, count));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs9 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 9);
var $author$project$Main$frameContainer = F5(
	function (displayState, title, errors, inputContent, openMsg) {
		switch (displayState) {
			case 2:
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('border-bottom'),
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pt3,
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb3
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs9]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h4,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-weight-bold')
												]),
											_List_fromArray(
												[title]))
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-right cursor-pointer hover-underline text-success'),
													$elm$html$Html$Events$onClick(openMsg)
												]))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Change')
										]))
								]))
						]));
			case 1:
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('border-bottom'),
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pt3,
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb3
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$row,
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(_List_Nil)
								]),
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h4,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-weight-bold')
												]),
											_List_fromArray(
												[title]))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_Nil,
									$author$project$Main$errorMessages(errors))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$row,
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(_List_Nil)
								]),
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_Nil,
									_List_fromArray(
										[inputContent]))
								]))
						]));
			default:
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	});
var $author$project$Main$titleWithData = F2(
	function (title, data) {
		return A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(title + ': '),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-primary')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(data)
						]))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$General$Internal$Center = 1;
var $rundis$elm_bootstrap$Bootstrap$General$Internal$MD = 2;
var $rundis$elm_bootstrap$Bootstrap$General$Internal$HAlign = F2(
	function (screenSize, align) {
		return {dV: align, eP: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowHAlign = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign = F2(
	function (size, align) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowHAlign(
			A2($rundis$elm_bootstrap$Bootstrap$General$Internal$HAlign, size, align));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$centerMd = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign, 2, 1);
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col3 = 3;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$md3 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 2, 3);
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3 = $elm$html$Html$Attributes$class('mt-3');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4 = $elm$html$Html$Attributes$class('mt-4');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Number = 7;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Input = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Type = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$create = F2(
	function (tipe, options) {
		return {
			dx: A2(
				$elm$core$List$cons,
				$rundis$elm_bootstrap$Bootstrap$Form$Input$Type(tipe),
				options)
		};
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						eQ: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						bb: $elm$core$Maybe$Just(id_)
					});
			case 2:
				var tipe = modifier.a;
				return _Utils_update(
					options,
					{br: tipe});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{b2: val});
			case 4:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						T: $elm$core$Maybe$Just(value_)
					});
			case 7:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						cD: $elm$core$Maybe$Just(value_)
					});
			case 5:
				var onInput_ = modifier.a;
				return _Utils_update(
					options,
					{
						cs: $elm$core$Maybe$Just(onInput_)
					});
			case 6:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						c6: $elm$core$Maybe$Just(validation_)
					});
			case 8:
				var val = modifier.a;
				return _Utils_update(
					options,
					{cO: val});
			case 9:
				var val = modifier.a;
				return _Utils_update(
					options,
					{bp: val});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						bM: _Utils_ap(options.bM, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Text = 0;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions = {bM: _List_Nil, b2: false, bb: $elm$core$Maybe$Nothing, cs: $elm$core$Maybe$Nothing, cD: $elm$core$Maybe$Nothing, bp: false, cO: false, eQ: $elm$core$Maybe$Nothing, br: 0, c6: $elm$core$Maybe$Nothing, T: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute = function (size) {
	return A2(
		$elm$core$Maybe$map,
		function (s) {
			return $elm$html$Html$Attributes$class('form-control-' + s);
		},
		$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute = function (inputType) {
	return $elm$html$Html$Attributes$type_(
		function () {
			switch (inputType) {
				case 0:
					return 'text';
				case 1:
					return 'password';
				case 2:
					return 'datetime-local';
				case 3:
					return 'date';
				case 4:
					return 'month';
				case 5:
					return 'time';
				case 6:
					return 'week';
				case 7:
					return 'number';
				case 8:
					return 'email';
				case 9:
					return 'url';
				case 10:
					return 'search';
				case 11:
					return 'tel';
				default:
					return 'color';
			}
		}());
};
var $rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString = function (validation) {
	if (!validation) {
		return 'is-valid';
	} else {
		return 'is-invalid';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute = function (validation) {
	return $elm$html$Html$Attributes$class(
		$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				options.bp ? 'form-control-plaintext' : 'form-control'),
				$elm$html$Html$Attributes$disabled(options.b2),
				$elm$html$Html$Attributes$readonly(options.cO || options.bp),
				$rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute(options.br)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.bb),
						A2($elm$core$Maybe$andThen, $rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute, options.eQ),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$value, options.T),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, options.cD),
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onInput, options.cs),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute, options.c6)
					])),
			options.bM));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$view = function (_v0) {
	var options = _v0.dx;
	return A2(
		$elm$html$Html$input,
		$rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes(options),
		_List_Nil);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$input = F2(
	function (tipe, options) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Input$view(
			A2($rundis$elm_bootstrap$Bootstrap$Form$Input$create, tipe, options));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$number = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(7);
var $rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput = function (a) {
	return {$: 5, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$onInput = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput(toMsg);
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl0 = $elm$html$Html$Attributes$class('pl-0');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr0 = $elm$html$Html$Attributes$class('pr-0');
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Secondary = 1;
var $rundis$elm_bootstrap$Bootstrap$Button$secondary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(1));
var $author$project$SelectButton$selectButton = F4(
	function (msg, displayText, value, currentVal) {
		var selected = _Utils_eq(currentVal, value);
		var color = selected ? $rundis$elm_bootstrap$Bootstrap$Button$success : $rundis$elm_bootstrap$Bootstrap$Button$secondary;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					color,
					$rundis$elm_bootstrap$Bootstrap$Button$block,
					$rundis$elm_bootstrap$Bootstrap$Button$attrs(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4,
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p2,
							$elm$html$Html$Attributes$class('font-weight-bold')
						])),
					$rundis$elm_bootstrap$Bootstrap$Button$onClick(
					msg(value))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(displayText)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col6 = 6;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 6);
var $author$project$AmountSelector$view = F4(
	function (selectMsg, currentVal, submitMsg, buttonVisible) {
		var buttonRow = buttonVisible ? _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A5($author$project$SubmitButton$submitButton, submitMsg, 'Continue', false, true, 'amountBtn')
							]))
					]))
			]) : _List_Nil;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr0, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl0]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Grid$Row$centerMd]),
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md3, $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_List_fromArray(
									[
										A4($author$project$SelectButton$selectButton, selectMsg, '$10', '10', currentVal)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md3, $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_List_fromArray(
									[
										A4($author$project$SelectButton$selectButton, selectMsg, '$25', '25', currentVal)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md3, $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_List_fromArray(
									[
										A4($author$project$SelectButton$selectButton, selectMsg, '$50', '50', currentVal)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md3, $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_List_fromArray(
									[
										A4($author$project$SelectButton$selectButton, selectMsg, '$100', '100', currentVal)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md3, $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_List_fromArray(
									[
										A4($author$project$SelectButton$selectButton, selectMsg, '$150', '150', currentVal)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md3, $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_List_fromArray(
									[
										A4($author$project$SelectButton$selectButton, selectMsg, '$250', '250', currentVal)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md3, $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_List_fromArray(
									[
										A4($author$project$SelectButton$selectButton, selectMsg, '$1000', '1000', currentVal)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md3, $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]),
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Form$Input$number(
												_List_fromArray(
													[
														$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(selectMsg)
													]))
											]))
									]))
							]))
					]),
				buttonRow));
	});
var $author$project$Main$donationAmountView = function (model) {
	var title = ($elm$core$String$length(model.V) > 0) ? A2($author$project$Main$titleWithData, 'Donation Amount', '$' + model.V) : $elm$html$Html$text('Donation Amount');
	return A5(
		$author$project$Main$frameContainer,
		model.H,
		title,
		model.b,
		A4($author$project$AmountSelector$view, $author$project$Main$AmountUpdated, model.V, $author$project$Main$SubmitAmount, !model.n),
		$author$project$Main$OpenDonationAmount);
};
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $elm$html$Html$p = _VirtualDom_node('p');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p4 = $elm$html$Html$Attributes$class('p-4');
var $author$project$Content$IanCain$footerCopy = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p4]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h5,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('font-weight-heavy')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Contribution rules')
					])),
				A2(
				$elm$html$Html$ol,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I am at least eighteen years old.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('This contribution is made from my own funds, and funds are not being provided to me by another person or entity for the purpose of making this contribution.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I am a U.S. citizen or lawfully admitted permanent resident (i.e., green card holder).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I am making this contribution with my own personal credit card and not with a corporate or business credit card or a card issued to another person.')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('In accordance with OCPF regulations individuals may contribute up to $1,000 annually to a candidate. Spouses may contribute jointly provided that both names are pre-printed on the check. All contributions over $200 require employer/occupation information. Lobbyists are limited to $200 annually to a candidate. Corporate and LLC contributions are prohibited. Money order and bank check contributions are limited to $100 per person per year.')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Cash contributions are limited to $50 per person per year.')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Donations are not deductible for income tax purposes.')
					]))
			]))
	]);
var $author$project$Main$footerCopy = function (model) {
	var _v0 = model.a6;
	if (_v0 === 'ian-cain') {
		return $author$project$Content$IanCain$footerCopy;
	} else {
		return _List_Nil;
	}
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Asset$src = function (_v0) {
	var url = _v0;
	return $elm$html$Html$Attributes$src(url);
};
var $author$project$Asset$Image = $elm$core$Basics$identity;
var $author$project$Asset$image = function (filename) {
	return '/assets/images/' + filename;
};
var $author$project$Asset$usLogo = $author$project$Asset$image('logo-hires.png');
var $author$project$Main$logoDiv = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('text-center')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$author$project$Asset$src($author$project$Asset$usLogo),
					$elm$html$Html$Attributes$class('logo-small'),
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4
				]),
			_List_Nil)
		]));
var $author$project$Asset$usLogoWing = $author$project$Asset$image('logo-hires-wing.png');
var $author$project$Main$logoWingDiv = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('text-center')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$author$project$Asset$src($author$project$Asset$usLogoWing),
					$elm$html$Html$Attributes$class('logo-medium'),
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4
				]),
			_List_Nil)
		]));
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$m4 = $elm$html$Html$Attributes$class('m-4');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5 = $elm$html$Html$Attributes$class('mt-5');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb5 = $elm$html$Html$Attributes$class('pb-5');
var $author$project$Main$OpenDonorInfo = {$: 36};
var $author$project$Main$UpdateAttestation = function (a) {
	return {$: 39, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Checkbox = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create = F2(
	function (options, label_) {
		return {en: label_, dx: options};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						bb: $elm$core$Maybe$Just(val)
					});
			case 1:
				var val = modifier.a;
				return _Utils_update(
					options,
					{aA: val});
			case 2:
				return _Utils_update(
					options,
					{bc: true});
			case 3:
				var toMsg = modifier.a;
				return _Utils_update(
					options,
					{
						cq: $elm$core$Maybe$Just(toMsg)
					});
			case 4:
				return _Utils_update(
					options,
					{t: true});
			case 5:
				var val = modifier.a;
				return _Utils_update(
					options,
					{b2: val});
			case 6:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						c6: $elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						bM: _Utils_ap(options.bM, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Off = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions = {bM: _List_Nil, t: false, b2: false, bb: $elm$core$Maybe$Nothing, bc: false, cq: $elm$core$Maybe$Nothing, aA: 1, c6: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute = function (state) {
	switch (state) {
		case 0:
			return $elm$html$Html$Attributes$checked(true);
		case 1:
			return $elm$html$Html$Attributes$checked(false);
		default:
			return A2($elm$html$Html$Attributes$attribute, 'indeterminate', 'true');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check-input', !options.t),
						_Utils_Tuple2('custom-control-input', options.t)
					])),
				$elm$html$Html$Attributes$type_('checkbox'),
				$elm$html$Html$Attributes$disabled(options.b2),
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute(options.aA)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onCheck, options.cq),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.bb)
					])),
			_Utils_ap(
				function () {
					var _v0 = options.c6;
					if (!_v0.$) {
						var v = _v0.a;
						return _List_fromArray(
							[
								$elm$html$Html$Attributes$class(
								$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(v))
							]);
					} else {
						return _List_Nil;
					}
				}(),
				options.bM)));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view = function (_v0) {
	var chk = _v0;
	var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions, chk.dx);
	var _v1 = chk.en;
	var label_ = _v1;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check', !opts.t),
						_Utils_Tuple2('form-check-inline', (!opts.t) && opts.bc),
						_Utils_Tuple2('custom-control', opts.t),
						_Utils_Tuple2('custom-checkbox', opts.t),
						_Utils_Tuple2('custom-control-inline', opts.bc && opts.t)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes(opts),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_Utils_ap(
					label_.bM,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-check-label', !opts.t),
										_Utils_Tuple2('custom-control-label', opts.t)
									]))
							]),
						function () {
							var _v2 = opts.bb;
							if (!_v2.$) {
								var v = _v2.a;
								return _List_fromArray(
									[
										$elm$html$Html$Attributes$for(v)
									]);
							} else {
								return _List_Nil;
							}
						}())),
				label_.d_)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$advancedCheckbox = F2(
	function (options, label_) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view(
			A2($rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create, options, label_));
	});
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2 = $elm$html$Html$Attributes$class('ml-2');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1 = $elm$html$Html$Attributes$class('mt-1');
var $author$project$Copy$attestation = A2(
	$elm$core$List$intersperse,
	A2($elm$html$Html$br, _List_Nil, _List_Nil),
	A2(
		$elm$core$List$map,
		function (n) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2]),
				_List_fromArray(
					[
						$elm$html$Html$text(n)
					]));
		},
		_List_fromArray(
			['1. I am at least eighteen years old.', '2. This contribution is made from my own funds, and funds are not being provided to me by another person or entity for the purpose of making this contribution. ', '3. I am a U.S. citizen or lawfully admitted permanent resident (i.e., green card holder).', '4. I am making this contribution with my own personal credit card and not with a corporate or business credit card or a card issued to another person. '])));
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$On = 0;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Value = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked = function (isCheck) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Value(
		isCheck ? 0 : 1);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Id = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$id = function (theId) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Id(theId);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Label = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$label = F2(
	function (attributes, children) {
		return {bM: attributes, d_: children};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked(toMsg);
};
var $author$project$Main$attestationRow = function (model) {
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$advancedCheckbox,
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$id('attestation'),
									$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.ai),
									$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck($author$project$Main$UpdateAttestation)
								]),
							A2(
								$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$label,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'data-cy', 'contribAffirm')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('By making this contribution I affirm that:')
									])))
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1]))
				]),
			_List_fromArray(
				[
					A2($rundis$elm_bootstrap$Bootstrap$Grid$col, _List_Nil, $author$project$Copy$attestation)
				]))
		]);
};
var $author$project$Main$donorInfoTitle = function (model) {
	var _v0 = model.av;
	if (!_v0.$) {
		if (!_v0.a) {
			var _v1 = _v0.a;
			return ($elm$core$String$length(model._) > 0) ? A2($author$project$Main$titleWithData, 'My Info', model._) : $elm$html$Html$text('My Info');
		} else {
			var _v2 = _v0.a;
			var fullName = model.aa + (' ' + model.ab);
			return ($elm$core$String$length(fullName) > 1) ? A2($author$project$Main$titleWithData, 'My Info', fullName) : $elm$html$Html$text('My Info');
		}
	} else {
		return $elm$html$Html$text('My Info');
	}
};
var $author$project$Main$UpdateEmployer = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$UpdateOccupation = function (a) {
	return {$: 13, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs = function (a) {
	return {$: 10, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Placeholder = function (a) {
	return {$: 7, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Placeholder(value_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$text = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(0);
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Value = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$value = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Value(value_);
};
var $author$project$AppInput$inputText = F4(
	function (msg, placeholder, val, testAttr) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder(placeholder),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'data-cy', testAttr)
						]))
				]));
	});
var $author$project$Main$employerOccupationRow = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_Nil,
				_List_fromArray(
					[
						A4($author$project$AppInput$inputText, $author$project$Main$UpdateEmployer, 'Employer Name', model.aN, 'contribIndEmployerName')
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_Nil,
				_List_fromArray(
					[
						A4($author$project$AppInput$inputText, $author$project$Main$UpdateOccupation, 'Occupation', model.aT, 'contribIndOccupation')
					]))
			]));
};
var $author$project$EmploymentStatus$Retired = 2;
var $author$project$EmploymentStatus$SelfEmployed = 1;
var $author$project$EmploymentStatus$Unemployed = 3;
var $author$project$Main$UpdateEmploymentStatus = function (a) {
	return {$: 11, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Radio = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$addOption = F2(
	function (opt, _v0) {
		var radio_ = _v0;
		var options = radio_.dx;
		return _Utils_update(
			radio_,
			{
				dx: A2($elm$core$List$cons, opt, options)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Name = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$name = function (name_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$Name(name_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						bb: $elm$core$Maybe$Just(val)
					});
			case 1:
				var val = modifier.a;
				return _Utils_update(
					options,
					{bW: val});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						fG: $elm$core$Maybe$Just(val)
					});
			case 2:
				return _Utils_update(
					options,
					{bc: true});
			case 4:
				var toMsg = modifier.a;
				return _Utils_update(
					options,
					{
						cr: $elm$core$Maybe$Just(toMsg)
					});
			case 5:
				return _Utils_update(
					options,
					{t: true});
			case 6:
				var val = modifier.a;
				return _Utils_update(
					options,
					{b2: val});
			case 7:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						c6: $elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						bM: _Utils_ap(options.bM, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$defaultOptions = {bM: _List_Nil, bW: false, t: false, b2: false, bb: $elm$core$Maybe$Nothing, bc: false, fG: $elm$core$Maybe$Nothing, cr: $elm$core$Maybe$Nothing, c6: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$toAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check-input', !options.t),
						_Utils_Tuple2('custom-control-input', options.t)
					])),
				$elm$html$Html$Attributes$type_('radio'),
				$elm$html$Html$Attributes$disabled(options.b2),
				$elm$html$Html$Attributes$checked(options.bW)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, options.cr),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.fG),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.bb)
					])),
			options.bM));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$view = function (_v0) {
	var radio_ = _v0;
	var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Radio$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Radio$defaultOptions, radio_.dx);
	var _v1 = radio_.en;
	var label_ = _v1;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check', !opts.t),
						_Utils_Tuple2('form-check-inline', (!opts.t) && opts.bc),
						_Utils_Tuple2('custom-control', opts.t),
						_Utils_Tuple2('custom-radio', opts.t),
						_Utils_Tuple2('custom-control-inline', opts.bc && opts.t)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$toAttributes(opts),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_Utils_ap(
					label_.bM,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-check-label', !opts.t),
										_Utils_Tuple2('custom-control-label', opts.t)
									]))
							]),
						function () {
							var _v2 = opts.bb;
							if (!_v2.$) {
								var v = _v2.a;
								return _List_fromArray(
									[
										$elm$html$Html$Attributes$for(v)
									]);
							} else {
								return _List_Nil;
							}
						}())),
				label_.d_)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList = F2(
	function (groupName, radios) {
		return A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeL,
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$view,
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$addOption(
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$name(groupName))),
			radios);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Checked = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$checked = function (isCheck) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$Checked(isCheck);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Custom = {$: 5};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$createAdvanced = F2(
	function (options, label_) {
		return {en: label_, dx: options};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Label = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$label = F2(
	function (attributes, children) {
		return {bM: attributes, d_: children};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$create = F2(
	function (options, label_) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Radio$createAdvanced,
			options,
			A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(label_)
					])));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom = function (options) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$create(
		A2($elm$core$List$cons, $rundis$elm_bootstrap$Bootstrap$Form$Radio$Custom, options));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Id = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$id = function (theId) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$Id(theId);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Inline = {$: 2};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$inline = $rundis$elm_bootstrap$Bootstrap$Form$Radio$Inline;
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$OnClick = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$OnClick(toMsg);
};
var $author$project$SelectRadio$view = F4(
	function (msg, dataValue, displayValue, currentValue) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(dataValue),
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(msg),
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
					_Utils_eq(currentValue, dataValue))
				]),
			displayValue);
	});
var $author$project$Main$employmentStatusRows = function (model) {
	var currentVal = $author$project$EmploymentStatus$toString(model.J);
	var _v0 = model.as;
	if (!_v0) {
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Employment status:')
							]))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList,
							'employmentStatus',
							_List_fromArray(
								[
									A4(
									$author$project$SelectRadio$view,
									$author$project$Main$UpdateEmploymentStatus(0),
									'Employed',
									'Employed',
									currentVal),
									A4(
									$author$project$SelectRadio$view,
									$author$project$Main$UpdateEmploymentStatus(3),
									'Unemployed',
									'Unemployed',
									currentVal),
									A4(
									$author$project$SelectRadio$view,
									$author$project$Main$UpdateEmploymentStatus(2),
									'Retired',
									'Retired',
									currentVal),
									A4(
									$author$project$SelectRadio$view,
									$author$project$Main$UpdateEmploymentStatus(1),
									'SelfEmployed',
									'Self Employed',
									currentVal)
								])))
					]))
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Main$needEmployerName = function (val) {
	_v0$2:
	while (true) {
		if (!val.$) {
			switch (val.a) {
				case 0:
					var _v1 = val.a;
					return true;
				case 1:
					var _v2 = val.a;
					return true;
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return false;
};
var $author$project$Main$employmentRows = function (model) {
	var employerRowOrEmpty = $author$project$Main$needEmployerName(model.J) ? _List_fromArray(
		[
			$author$project$Main$employerOccupationRow(model)
		]) : _List_Nil;
	return _Utils_ap(
		$author$project$Main$employmentStatusRows(model),
		employerRowOrEmpty);
};
var $author$project$Main$UpdateFamilyOrIndividual = function (a) {
	return {$: 16, a: a};
};
var $author$project$EntityType$Candidate = 4;
var $author$project$EntityType$Family = 0;
var $author$project$EntityType$Individual = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced = function (options) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$createAdvanced(
		A2($elm$core$List$cons, $rundis$elm_bootstrap$Bootstrap$Form$Radio$Custom, options));
};
var $author$project$EntityType$candidateRelationshipRadioList = F2(
	function (msg, currentValue) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList,
			'candidateRelationship',
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$id('ind'),
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
							msg(1)),
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
							_Utils_eq(
								currentValue,
								$elm$core$Maybe$Just(1)))
						]),
					A2(
						$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'data-cy', 'contribIndNA')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Not Related')
							]))),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$id('can'),
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
							msg(4)),
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
							_Utils_eq(
								currentValue,
								$elm$core$Maybe$Just(4)))
						]),
					A2(
						$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'data-cy', 'contribIndCAN')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('The candidate or spouse of the candidate')
							]))),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$id('fam'),
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
							msg(0)),
							$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
							_Utils_eq(
								currentValue,
								$elm$core$Maybe$Just(0)))
						]),
					A2(
						$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Family member* of the candidate'),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1,
										$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2,
										A2($elm$html$Html$Attributes$attribute, 'data-cy', 'contribIndFAM')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('*Defined as the candidate\'s child, parent, grandparent, brother, or sister of any such persons ')
									]))
							])))
				]));
	});
var $author$project$Main$familyRow = function (model) {
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Select your relation to the candidate:')
								]))
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					A2($author$project$EntityType$candidateRelationshipRadioList, $author$project$Main$UpdateFamilyOrIndividual, model.D))
				]))
		]);
};
var $author$project$Main$ChooseOrgOrInd = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2 = $elm$html$Html$Attributes$class('mt-2');
var $author$project$OrgOrInd$Org = 0;
var $author$project$OrgOrInd$selectButton = F4(
	function (msg, displayText, value, currentVal) {
		var selected = _Utils_eq(currentVal, value);
		var color = selected ? $rundis$elm_bootstrap$Bootstrap$Button$success : $rundis$elm_bootstrap$Bootstrap$Button$secondary;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							color,
							$rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id(displayText)
								])),
							$rundis$elm_bootstrap$Bootstrap$Button$block,
							$rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-weight-bold border-round')
								])),
							$rundis$elm_bootstrap$Bootstrap$Button$onClick(
							msg(value))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(displayText)
						]))
				]));
	});
var $author$project$OrgOrInd$toString = function (orgOrInd) {
	if (!orgOrInd) {
		return 'Organization';
	} else {
		return 'Individual';
	}
};
var $author$project$OrgOrInd$row = F2(
	function (msg, currentValue) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$author$project$OrgOrInd$selectButton,
							msg,
							$author$project$OrgOrInd$toString(1),
							$elm$core$Maybe$Just(1),
							currentValue)
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A4(
							$author$project$OrgOrInd$selectButton,
							msg,
							$author$project$OrgOrInd$toString(0),
							$elm$core$Maybe$Just(0),
							currentValue)
						]))
				]));
	});
var $author$project$Main$orgOrIndRow = function (model) {
	var _v0 = model.dJ.fc;
	if (_v0) {
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Will I be donating as an individual or on behalf of an organization?')
							]))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$OrgOrInd$row, $author$project$Main$ChooseOrgOrInd, model.av)
							]))
					]))
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Main$UpdateOrganizationClassification = function (a) {
	return {$: 15, a: a};
};
var $author$project$Main$UpdateOrganizationName = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$AddOwner = {$: 17};
var $author$project$Main$UpdateOwnerAddress1 = function (a) {
	return {$: 21, a: a};
};
var $author$project$Main$UpdateOwnerAddress2 = function (a) {
	return {$: 22, a: a};
};
var $author$project$Main$UpdateOwnerCity = function (a) {
	return {$: 23, a: a};
};
var $author$project$Main$UpdateOwnerFirstName = function (a) {
	return {$: 19, a: a};
};
var $author$project$Main$UpdateOwnerLastName = function (a) {
	return {$: 20, a: a};
};
var $author$project$Main$UpdateOwnerOwnership = function (a) {
	return {$: 26, a: a};
};
var $author$project$Main$UpdateOwnerPostalCode = function (a) {
	return {$: 25, a: a};
};
var $author$project$Main$UpdateOwnerState = function (a) {
	return {$: 24, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$Inversed = {$: 0};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Table$isResponsive = function (option) {
	if (option.$ === 5) {
		return true;
	} else {
		return false;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$KeyedTBody = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$TBody = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$InversedRow = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$Row = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$InversedCell = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$Td = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$Th = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell = function (cell) {
	var inverseOptions = function (options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				if (!opt.$) {
					var role = opt.a;
					return $rundis$elm_bootstrap$Bootstrap$Table$InversedCell(role);
				} else {
					return opt;
				}
			},
			options);
	};
	if (cell.$ === 1) {
		var cellCfg = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			_Utils_update(
				cellCfg,
				{
					dx: inverseOptions(cellCfg.dx)
				}));
	} else {
		var cellCfg = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Td(
			_Utils_update(
				cellCfg,
				{
					dx: inverseOptions(cellCfg.dx)
				}));
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow = function (row) {
	var inversedOptions = function (options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				if (!opt.$) {
					var role = opt.a;
					return $rundis$elm_bootstrap$Bootstrap$Table$InversedRow(role);
				} else {
					return opt;
				}
			},
			options);
	};
	if (!row.$) {
		var options = row.a.dx;
		var cells = row.a.p;
		return $rundis$elm_bootstrap$Bootstrap$Table$Row(
			{
				p: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell, cells),
				dx: inversedOptions(options)
			});
	} else {
		var options = row.a.dx;
		var cells = row.a.p;
		return $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow(
			{
				p: A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var cell = _v1.b;
						return _Utils_Tuple2(
							key,
							$rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell(cell));
					},
					cells),
				dx: inversedOptions(options)
			});
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTBody = F2(
	function (isTableInversed, tbody_) {
		var _v0 = _Utils_Tuple2(isTableInversed, tbody_);
		if (!_v0.a) {
			return tbody_;
		} else {
			if (!_v0.b.$) {
				var body = _v0.b.a;
				return $rundis$elm_bootstrap$Bootstrap$Table$TBody(
					_Utils_update(
						body,
						{
							cQ: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow, body.cQ)
						}));
			} else {
				var keyedBody = _v0.b.a;
				return $rundis$elm_bootstrap$Bootstrap$Table$KeyedTBody(
					_Utils_update(
						keyedBody,
						{
							cQ: A2(
								$elm$core$List$map,
								function (_v1) {
									var key = _v1.a;
									var row = _v1.b;
									return _Utils_Tuple2(
										key,
										$rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow(row));
								},
								keyedBody.cQ)
						}));
			}
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Table$InversedHead = {$: 0};
var $rundis$elm_bootstrap$Bootstrap$Table$THead = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTHead = F2(
	function (isTableInversed, _v0) {
		var thead_ = _v0;
		var isHeadInversed = A2(
			$elm$core$List$any,
			function (opt) {
				return _Utils_eq(opt, $rundis$elm_bootstrap$Bootstrap$Table$InversedHead);
			},
			thead_.dx);
		return (isTableInversed || isHeadInversed) ? _Utils_update(
			thead_,
			{
				cQ: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow, thead_.cQ)
			}) : thead_;
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$maybeWrapResponsive = F2(
	function (options, table_) {
		var responsiveClass = $elm$html$Html$Attributes$class(
			'table-responsive' + A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (v) {
						return '-' + v;
					},
					A2(
						$elm$core$Maybe$andThen,
						$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption,
						A2(
							$elm$core$Maybe$andThen,
							function (opt) {
								if (opt.$ === 5) {
									var val = opt.a;
									return val;
								} else {
									return $elm$core$Maybe$Nothing;
								}
							},
							$elm$core$List$head(
								A2($elm$core$List$filter, $rundis$elm_bootstrap$Bootstrap$Table$isResponsive, options)))))));
		return A2($elm$core$List$any, $rundis$elm_bootstrap$Bootstrap$Table$isResponsive, options) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[responsiveClass]),
			_List_fromArray(
				[table_])) : table_;
	});
var $rundis$elm_bootstrap$Bootstrap$Table$CellAttr = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttr = function (attr_) {
	return $rundis$elm_bootstrap$Bootstrap$Table$CellAttr(attr_);
};
var $elm$html$Html$Attributes$scope = $elm$html$Html$Attributes$stringProperty('scope');
var $rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh = function (cell) {
	if (cell.$ === 1) {
		var cellConfig = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			_Utils_update(
				cellConfig,
				{
					dx: A2(
						$elm$core$List$cons,
						$rundis$elm_bootstrap$Bootstrap$Table$cellAttr(
							$elm$html$Html$Attributes$scope('row')),
						cellConfig.dx)
				}));
	} else {
		return cell;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell = function (row) {
	if (!row.$) {
		var options = row.a.dx;
		var cells = row.a.p;
		if (!cells.b) {
			return row;
		} else {
			var first = cells.a;
			var rest = cells.b;
			return $rundis$elm_bootstrap$Bootstrap$Table$Row(
				{
					p: A2(
						$elm$core$List$cons,
						$rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh(first),
						rest),
					dx: options
				});
		}
	} else {
		var options = row.a.dx;
		var cells = row.a.p;
		if (!cells.b) {
			return row;
		} else {
			var _v3 = cells.a;
			var firstKey = _v3.a;
			var first = _v3.b;
			var rest = cells.b;
			return $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow(
				{
					p: A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							firstKey,
							$rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh(first)),
						rest),
					dx: options
				});
		}
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttribute = function (option) {
	switch (option.$) {
		case 0:
			if (!option.a.$) {
				var role = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'table', role);
			} else {
				var _v1 = option.a;
				return $elm$html$Html$Attributes$class('table-active');
			}
		case 1:
			if (!option.a.$) {
				var role = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg-', role);
			} else {
				var _v2 = option.a;
				return $elm$html$Html$Attributes$class('bg-active');
			}
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$cellAttribute, options);
};
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $rundis$elm_bootstrap$Bootstrap$Table$renderCell = function (cell) {
	if (!cell.$) {
		var options = cell.a.dx;
		var children = cell.a.d_;
		return A2(
			$elm$html$Html$td,
			$rundis$elm_bootstrap$Bootstrap$Table$cellAttributes(options),
			children);
	} else {
		var options = cell.a.dx;
		var children = cell.a.d_;
		return A2(
			$elm$html$Html$th,
			$rundis$elm_bootstrap$Bootstrap$Table$cellAttributes(options),
			children);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$rowClass = function (option) {
	switch (option.$) {
		case 0:
			if (!option.a.$) {
				var role_ = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'table', role_);
			} else {
				var _v1 = option.a;
				return $elm$html$Html$Attributes$class('table-active');
			}
		case 1:
			if (!option.a.$) {
				var role_ = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role_);
			} else {
				var _v2 = option.a;
				return $elm$html$Html$Attributes$class('bg-active');
			}
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$rowAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$rowClass, options);
};
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $rundis$elm_bootstrap$Bootstrap$Table$renderRow = function (row) {
	if (!row.$) {
		var options = row.a.dx;
		var cells = row.a.p;
		return A2(
			$elm$html$Html$tr,
			$rundis$elm_bootstrap$Bootstrap$Table$rowAttributes(options),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$renderCell, cells));
	} else {
		var options = row.a.dx;
		var cells = row.a.p;
		return A3(
			$elm$html$Html$Keyed$node,
			'tr',
			$rundis$elm_bootstrap$Bootstrap$Table$rowAttributes(options),
			A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var cell = _v1.b;
					return _Utils_Tuple2(
						key,
						$rundis$elm_bootstrap$Bootstrap$Table$renderCell(cell));
				},
				cells));
	}
};
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $rundis$elm_bootstrap$Bootstrap$Table$renderTBody = function (body) {
	if (!body.$) {
		var attributes = body.a.bM;
		var rows = body.a.cQ;
		return A2(
			$elm$html$Html$tbody,
			attributes,
			A2(
				$elm$core$List$map,
				function (row) {
					return $rundis$elm_bootstrap$Bootstrap$Table$renderRow(
						$rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell(row));
				},
				rows));
	} else {
		var attributes = body.a.bM;
		var rows = body.a.cQ;
		return A3(
			$elm$html$Html$Keyed$node,
			'tbody',
			attributes,
			A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var row = _v1.b;
					return _Utils_Tuple2(
						key,
						$rundis$elm_bootstrap$Bootstrap$Table$renderRow(
							$rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell(row)));
				},
				rows));
	}
};
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $rundis$elm_bootstrap$Bootstrap$Table$theadAttribute = function (option) {
	switch (option.$) {
		case 0:
			return $elm$html$Html$Attributes$class('thead-dark');
		case 1:
			return $elm$html$Html$Attributes$class('thead-default');
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$theadAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$theadAttribute, options);
};
var $rundis$elm_bootstrap$Bootstrap$Table$renderTHead = function (_v0) {
	var options = _v0.dx;
	var rows = _v0.cQ;
	return A2(
		$elm$html$Html$thead,
		$rundis$elm_bootstrap$Bootstrap$Table$theadAttributes(options),
		A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$renderRow, rows));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $rundis$elm_bootstrap$Bootstrap$Table$tableClass = function (option) {
	switch (option.$) {
		case 0:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-dark'));
		case 1:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-striped'));
		case 2:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-bordered'));
		case 3:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-hover'));
		case 4:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-sm'));
		case 5:
			return $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-reflow'));
		default:
			var attr_ = option.a;
			return $elm$core$Maybe$Just(attr_);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$tableAttributes = function (options) {
	return A2(
		$elm$core$List$cons,
		$elm$html$Html$Attributes$class('table'),
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$tableClass, options)));
};
var $rundis$elm_bootstrap$Bootstrap$Table$table = function (rec) {
	var isInversed = A2(
		$elm$core$List$any,
		function (opt) {
			return _Utils_eq(opt, $rundis$elm_bootstrap$Bootstrap$Table$Inversed);
		},
		rec.dx);
	var classOptions = A2(
		$elm$core$List$filter,
		function (opt) {
			return !$rundis$elm_bootstrap$Bootstrap$Table$isResponsive(opt);
		},
		rec.dx);
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$maybeWrapResponsive,
		rec.dx,
		A2(
			$elm$html$Html$table,
			$rundis$elm_bootstrap$Bootstrap$Table$tableAttributes(classOptions),
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Table$renderTHead(
					A2($rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTHead, isInversed, rec.eU)),
					$rundis$elm_bootstrap$Bootstrap$Table$renderTBody(
					A2($rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTBody, isInversed, rec.eT))
				])));
};
var $rundis$elm_bootstrap$Bootstrap$Table$simpleTable = function (_v0) {
	var thead_ = _v0.a;
	var tbody_ = _v0.b;
	return $rundis$elm_bootstrap$Bootstrap$Table$table(
		{dx: _List_Nil, eT: tbody_, eU: thead_});
};
var $author$project$Main$DeleteOwner = function (a) {
	return {$: 18, a: a};
};
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Asset$glyph = F2(
	function (name, more) {
		return A2(
			$elm$html$Html$i,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fa'),
						$elm$html$Html$Attributes$class(name)
					]),
				more),
			_List_Nil);
	});
var $author$project$Asset$deleteGlyph = $author$project$Asset$glyph('fas fa-trash');
var $rundis$elm_bootstrap$Bootstrap$Table$tbody = F2(
	function (attributes, rows) {
		return $rundis$elm_bootstrap$Bootstrap$Table$TBody(
			{bM: attributes, cQ: rows});
	});
var $rundis$elm_bootstrap$Bootstrap$Table$td = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Td(
			{d_: children, dx: options});
	});
var $author$project$Owners$toFullName = function (owner) {
	return owner.aa + (' ' + owner.ab);
};
var $rundis$elm_bootstrap$Bootstrap$Table$tr = F2(
	function (options, cells) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Row(
			{p: cells, dx: options});
	});
var $author$project$Main$tableBody = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (owner) {
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Table$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Table$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Owners$toFullName(owner))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Table$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(owner.dB)
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Table$td,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Main$DeleteOwner(owner))
										]),
									_List_fromArray(
										[
											$author$project$Asset$deleteGlyph(
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-danger cursor-pointer')
												]))
										]))
								]))
						]));
			},
			model.m));
};
var $rundis$elm_bootstrap$Bootstrap$Table$thead = F2(
	function (options, rows) {
		return {dx: options, cQ: rows};
	});
var $rundis$elm_bootstrap$Bootstrap$Table$simpleThead = function (cells) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, cells)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Table$th = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			{d_: children, dx: options});
	});
var $author$project$Main$tableHead = $rundis$elm_bootstrap$Bootstrap$Table$simpleThead(
	_List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Table$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Name')
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Table$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Percent Ownership')
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Table$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('')
				]))
		]));
var $author$project$Main$capTable = function (model) {
	return ($elm$core$List$length(model.m) > 0) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Table$simpleTable(
				_Utils_Tuple2(
					$author$project$Main$tableHead,
					$author$project$Main$tableBody(model)))
			])) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
};
var $author$project$AppInput$inputNumber = F4(
	function (msg, placeholder, val, testAttr) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder(placeholder),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'data-cy', testAttr)
						]))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3 = $elm$html$Html$Attributes$class('mb-3');
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Offset6 = 6;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColOffset = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Offset = F2(
	function (screenSize, offsetCount) {
		return {ex: offsetCount, eP: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offset = F2(
	function (size, count) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColOffset(
			A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Offset, size, count));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$offsetXs6 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$offset, 0, 6);
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Attrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Id = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$id = function (id_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$Id(id_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Item = $elm$core$Basics$identity;
var $elm$html$Html$option = _VirtualDom_node('option');
var $rundis$elm_bootstrap$Bootstrap$Form$Select$item = F2(
	function (attributes, children) {
		return A2($elm$html$Html$option, attributes, children);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$OnChange = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$onChange = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$OnChange(toMsg);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Select = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Select$create = F2(
	function (options, items) {
		return {el: items, dx: options};
	});
var $elm$html$Html$select = _VirtualDom_node('select');
var $rundis$elm_bootstrap$Bootstrap$Form$Select$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						eQ: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						bb: $elm$core$Maybe$Just(id_)
					});
			case 2:
				return _Utils_update(
					options,
					{t: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{b2: val});
			case 4:
				var onChange_ = modifier.a;
				return _Utils_update(
					options,
					{
						cp: $elm$core$Maybe$Just(onChange_)
					});
			case 5:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						c6: $elm$core$Maybe$Just(validation_)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						bM: _Utils_ap(options.bM, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$defaultOptions = {bM: _List_Nil, t: false, b2: false, bb: $elm$core$Maybe$Nothing, cp: $elm$core$Maybe$Nothing, eQ: $elm$core$Maybe$Nothing, c6: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute = F2(
	function (isCustom, size_) {
		var prefix = isCustom ? 'custom-select-' : 'form-control-';
		return A2(
			$elm$core$Maybe$map,
			function (s) {
				return $elm$html$Html$Attributes$class(
					_Utils_ap(prefix, s));
			},
			$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size_));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$validationAttribute = function (validation_) {
	return $elm$html$Html$Attributes$class(
		$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation_));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Select$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Select$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-control', !options.t),
						_Utils_Tuple2('custom-select', options.t)
					])),
				$elm$html$Html$Attributes$disabled(options.b2)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.bb),
						A2(
						$elm$core$Maybe$andThen,
						$rundis$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute(options.t),
						options.eQ),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange, options.cp),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Select$validationAttribute, options.c6)
					])),
			options.bM));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$view = function (_v0) {
	var options = _v0.dx;
	var items = _v0.el;
	return A2(
		$elm$html$Html$select,
		$rundis$elm_bootstrap$Bootstrap$Form$Select$toAttributes(options),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var e = _v1;
				return e;
			},
			items));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$select = F2(
	function (options, items) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Select$view(
			A2($rundis$elm_bootstrap$Bootstrap$Form$Select$create, options, items));
	});
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$State$statesAndAbbrsList = _List_fromArray(
	[
		_Utils_Tuple2('AL', 'Alabama'),
		_Utils_Tuple2('AK', 'Alaska'),
		_Utils_Tuple2('AS', 'American Samoa'),
		_Utils_Tuple2('AZ', 'Arizona'),
		_Utils_Tuple2('AR', 'Arkansas'),
		_Utils_Tuple2('CA', 'California'),
		_Utils_Tuple2('CO', 'Colorado'),
		_Utils_Tuple2('CT', 'Connecticut'),
		_Utils_Tuple2('DE', 'Delaware'),
		_Utils_Tuple2('DC', 'District of Columbia'),
		_Utils_Tuple2('FL', 'Florida'),
		_Utils_Tuple2('GA', 'Georgia'),
		_Utils_Tuple2('GU', 'Guam'),
		_Utils_Tuple2('HI', 'Hawaii'),
		_Utils_Tuple2('ID', 'Idaho'),
		_Utils_Tuple2('IL', 'Illinois'),
		_Utils_Tuple2('IN', 'Indiana'),
		_Utils_Tuple2('IA', 'Iowa'),
		_Utils_Tuple2('KS', 'Kansas'),
		_Utils_Tuple2('KY', 'Kentucky'),
		_Utils_Tuple2('LA', 'Louisiana'),
		_Utils_Tuple2('ME', 'Maine'),
		_Utils_Tuple2('MD', 'Maryland'),
		_Utils_Tuple2('MA', 'Massachusetts'),
		_Utils_Tuple2('MI', 'Michigan'),
		_Utils_Tuple2('MN', 'Minnesota'),
		_Utils_Tuple2('MS', 'Mississippi'),
		_Utils_Tuple2('MO', 'Missouri'),
		_Utils_Tuple2('MT', 'Montana'),
		_Utils_Tuple2('NE', 'Nebraska'),
		_Utils_Tuple2('NV', 'Nevada'),
		_Utils_Tuple2('NH', 'New Hampshire'),
		_Utils_Tuple2('NJ', 'New Jersey'),
		_Utils_Tuple2('NM', 'New Mexico'),
		_Utils_Tuple2('NY', 'New York'),
		_Utils_Tuple2('NC', 'North Carolina'),
		_Utils_Tuple2('ND', 'North Dakota'),
		_Utils_Tuple2('MP', 'Northern Mariana Islands'),
		_Utils_Tuple2('OH', 'Ohio'),
		_Utils_Tuple2('OK', 'Oklahoma'),
		_Utils_Tuple2('OR', 'Oregon'),
		_Utils_Tuple2('PA', 'Pennsylvania'),
		_Utils_Tuple2('PR', 'Puerto Rico'),
		_Utils_Tuple2('RI', 'Rhode Island'),
		_Utils_Tuple2('SC', 'South Carolina'),
		_Utils_Tuple2('SD', 'South Dakota'),
		_Utils_Tuple2('TN', 'Tennessee'),
		_Utils_Tuple2('TX', 'Texas'),
		_Utils_Tuple2('UT', 'Utah'),
		_Utils_Tuple2('VT', 'Vermont'),
		_Utils_Tuple2('VI', 'Virgin Islands'),
		_Utils_Tuple2('VA', 'Virginia'),
		_Utils_Tuple2('WA', 'Washington'),
		_Utils_Tuple2('WV', 'West Virginia'),
		_Utils_Tuple2('WI', 'Wisconsin'),
		_Utils_Tuple2('WY', 'Wyoming')
	]);
var $author$project$State$view = F3(
	function (msg, currentValue, testAttr) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Form$Select$id('State'),
					$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(msg),
					$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'data-cy', testAttr)
						]))
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value('')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('-- State --')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var abbr = _v0.a;
						var whole = _v0.b;
						return A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(abbr),
									$elm$html$Html$Attributes$selected(
									_Utils_eq(currentValue, abbr))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(whole)
								]));
					},
					$author$project$State$statesAndAbbrsList)));
	});
var $author$project$Main$manageOwnerRows = function (model) {
	return _Utils_ap(
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Please specify the current ownership breakdown of your company.')
							]))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('*Total percent ownership must equal 100%')
							]))
					]))
			]),
		_Utils_ap(
			_List_fromArray(
				[
					$author$project$Main$capTable(model)
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$AppInput$inputText, $author$project$Main$UpdateOwnerFirstName, 'First Name', model.aX, 'contribOrgfirstName')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$AppInput$inputText, $author$project$Main$UpdateOwnerLastName, 'Last Name', model.aY, 'contribOrgLastName')
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$AppInput$inputText, $author$project$Main$UpdateOwnerAddress1, 'Address 1', model.aU, 'contribOrgAddress1')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$AppInput$inputText, $author$project$Main$UpdateOwnerAddress2, 'Address 2', model.aV, 'contribOrgAddress2')
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$AppInput$inputText, $author$project$Main$UpdateOwnerCity, 'City', model.aW, 'contribOrgCity')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$State$view, $author$project$Main$UpdateOwnerState, model.a$, 'contribOrgState')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$AppInput$inputText, $author$project$Main$UpdateOwnerPostalCode, 'Postal Code', model.a_, 'contribOrgPostalCode')
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$AppInput$inputNumber, $author$project$Main$UpdateOwnerOwnership, 'Percent Ownership', model.aZ, 'contribOrgPercentOwnership')
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6, $rundis$elm_bootstrap$Bootstrap$Grid$Col$offsetXs6]),
							_List_fromArray(
								[
									A5($author$project$SubmitButton$submitButton, $author$project$Main$AddOwner, 'Add another member', false, true, 'addOwnerBtn')
								]))
						]))
				])));
};
var $author$project$EntityType$Association = 8;
var $author$project$EntityType$Committee = 6;
var $author$project$EntityType$Corporation = 5;
var $author$project$EntityType$Other = 12;
var $author$project$EntityType$PartnershipIncludingLLPs = 3;
var $author$project$EntityType$PoliticalActionCommittee = 10;
var $author$project$EntityType$PoliticalCommittee = 11;
var $author$project$EntityType$SoleProprietorship = 2;
var $author$project$EntityType$Union = 7;
var $author$project$EntityType$fromString = function (str) {
	switch (str) {
		case 'Fam':
			return $elm$core$Maybe$Just(0);
		case 'Ind':
			return $elm$core$Maybe$Just(1);
		case 'Solep':
			return $elm$core$Maybe$Just(2);
		case 'Part':
			return $elm$core$Maybe$Just(3);
		case 'Corp':
			return $elm$core$Maybe$Just(5);
		case 'Comm':
			return $elm$core$Maybe$Just(6);
		case 'Union':
			return $elm$core$Maybe$Just(7);
		case 'Assoc':
			return $elm$core$Maybe$Just(8);
		case 'Llc':
			return $elm$core$Maybe$Just(9);
		case 'Pac':
			return $elm$core$Maybe$Just(10);
		case 'Plc':
			return $elm$core$Maybe$Just(11);
		case 'Can':
			return $elm$core$Maybe$Just(4);
		case 'Oth':
			return $elm$core$Maybe$Just(12);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$EntityType$toDisplayString = function (contributorType) {
	switch (contributorType) {
		case 0:
			return 'Family';
		case 1:
			return 'Individual';
		case 2:
			return 'Sole Proprietorship';
		case 3:
			return 'Partnership including LLPs';
		case 5:
			return 'Corporation';
		case 6:
			return 'Committee';
		case 4:
			return 'Candidate';
		case 7:
			return 'Union';
		case 8:
			return 'Association';
		case 9:
			return 'Professional/Limited Liability Company';
		case 10:
			return 'Political Action Committee';
		case 11:
			return 'Political Committee';
		default:
			return 'Other';
	}
};
var $author$project$EntityType$orgSelect = F2(
	function (contributorType, currentValue) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$author$project$EntityType$toDataString(contributorType)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(
						$elm$core$Maybe$Just(contributorType),
						currentValue))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$EntityType$toDisplayString(contributorType))
				]));
	});
var $author$project$EntityType$orgView = F2(
	function (msg, currentValue) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Form$Select$id('contributorType'),
					$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(
					A2($elm$core$Basics$composeR, $author$project$EntityType$fromString, msg))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('-- Organization Classification --')
						])),
					A2($author$project$EntityType$orgSelect, 2, currentValue),
					A2($author$project$EntityType$orgSelect, 3, currentValue),
					A2($author$project$EntityType$orgSelect, 5, currentValue),
					A2($author$project$EntityType$orgSelect, 6, currentValue),
					A2($author$project$EntityType$orgSelect, 7, currentValue),
					A2($author$project$EntityType$orgSelect, 8, currentValue),
					A2($author$project$EntityType$orgSelect, 9, currentValue),
					A2($author$project$EntityType$orgSelect, 10, currentValue),
					A2($author$project$EntityType$orgSelect, 11, currentValue),
					A2($author$project$EntityType$orgSelect, 12, currentValue)
				]));
	});
var $author$project$Main$orgRows = function (model) {
	var llcRow = $author$project$Main$isLLCorLLPDonor(model) ? $author$project$Main$manageOwnerRows(model) : _List_Nil;
	return _Utils_ap(
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$EntityType$orgView, $author$project$Main$UpdateOrganizationClassification, model.D)
							]))
					]))
			]),
		_Utils_ap(
			llcRow,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$AppInput$inputText, $author$project$Main$UpdateOrganizationName, 'Organization Name', model._, 'contribOrgPostalCode')
								]))
						]))
				])));
};
var $author$project$Main$UpdateAddress1 = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$UpdateAddress2 = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$UpdateCity = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$UpdateEmailAddress = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$UpdateFirstName = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$UpdateLastName = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$UpdatePhoneNumber = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$UpdatePostalCode = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$UpdateState = function (a) {
	return {$: 9, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Email = 8;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$email = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(8);
var $author$project$AppInput$inputEmail = F4(
	function (msg, placeholder, val, testAttr) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Input$email(
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder(placeholder),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'data-cy', testAttr)
						]))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col4 = 4;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$md4 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 2, 4);
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$md6 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 2, 6);
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mtAutoMd = $elm$html$Html$Attributes$class('mt-md-auto');
var $author$project$Main$mobileColSpacing = _List_fromArray(
	[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mtAutoMd]);
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3Md = $elm$html$Html$Attributes$class('mt-md-3');
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col12 = 12;
var $rundis$elm_bootstrap$Bootstrap$General$Internal$SM = 1;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 12);
var $author$project$Main$piiRows = function (model) {
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3Md]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md6,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputEmail, $author$project$Main$UpdateEmailAddress, 'Email', model.aM, 'contribIndEmail')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md6,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputText, $author$project$Main$UpdatePhoneNumber, 'Phone Number', model.bo, 'contribIndPhoneNumber')
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3Md]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md6,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputText, $author$project$Main$UpdateFirstName, 'First Name', model.aa, 'contribIndFirstName')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md6,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputText, $author$project$Main$UpdateLastName, 'Last Name', model.ab, 'contribIndLastName')
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3Md]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md6,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputText, $author$project$Main$UpdateAddress1, 'Address 1', model.ah, 'contribIndAddress1')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md6,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputText, $author$project$Main$UpdateAddress2, 'Address 2', model.aG, 'contribIndAddress2')
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3Md]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputText, $author$project$Main$UpdateCity, 'City', model.aj, 'contribIndCity')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A3($author$project$State$view, $author$project$Main$UpdateState, model.aA, 'contribIndState')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputText, $author$project$Main$UpdatePostalCode, 'Zip', model.ae, 'contribIndPostalCode')
						]))
				]))
		]);
};
var $author$project$Main$SubmitDonorInfo = {$: 33};
var $author$project$Main$submitDonorButtonRow = function (attestation) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_Nil,
				_List_fromArray(
					[
						A5($author$project$SubmitButton$submitButton, $author$project$Main$SubmitDonorInfo, 'Continue', false, attestation, 'continueBtn')
					]))
			]));
};
var $author$project$Main$provideDonorInfoView = function (model) {
	var formRows = function () {
		var _v0 = model.av;
		if (!_v0.$) {
			if (!_v0.a) {
				var _v1 = _v0.a;
				return _Utils_ap(
					$author$project$Main$orgRows(model),
					_Utils_ap(
						$author$project$Main$piiRows(model),
						_Utils_ap(
							$author$project$Main$attestationRow(model),
							_List_fromArray(
								[
									$author$project$Main$submitDonorButtonRow(model.ai)
								]))));
			} else {
				var _v2 = _v0.a;
				return _Utils_ap(
					$author$project$Main$piiRows(model),
					_Utils_ap(
						$author$project$Main$employmentRows(model),
						_Utils_ap(
							$author$project$Main$familyRow(model),
							_Utils_ap(
								$author$project$Main$attestationRow(model),
								_List_fromArray(
									[
										$author$project$Main$submitDonorButtonRow(model.ai)
									])))));
			}
		} else {
			return _List_Nil;
		}
	}();
	return A5(
		$author$project$Main$frameContainer,
		model.I,
		$author$project$Main$donorInfoTitle(model),
		model.b,
		A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
			_List_Nil,
			_Utils_ap(
				$author$project$Main$orgOrIndRow(model),
				formRows)),
		$author$project$Main$OpenDonorInfo);
};
var $author$project$Main$OpenPaymentDetails = {$: 37};
var $author$project$Main$ToggleCardNumberVisibility = function (a) {
	return {$: 43, a: a};
};
var $author$project$Main$UpdateCVV = function (a) {
	return {$: 31, a: a};
};
var $author$project$Main$UpdateCardNumber = function (a) {
	return {$: 27, a: a};
};
var $author$project$Main$UpdateExpirationMonth = function (a) {
	return {$: 28, a: a};
};
var $author$project$Main$UpdateExpirationYear = function (a) {
	return {$: 29, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Password = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$password = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(1);
var $author$project$AppInput$inputSecure = F4(
	function (msg, placeholder, val, testAttr) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Input$password(
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder(placeholder),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'data-cy', testAttr)
						]))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox = F2(
	function (options, labelText) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create,
				options,
				A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(labelText)
						]))));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$applyModifier = F2(
	function (modifier, options) {
		var value = modifier;
		return _Utils_update(
			options,
			{
				bM: _Utils_ap(options.bM, value)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$defaultOptions = {bM: _List_Nil};
var $rundis$elm_bootstrap$Bootstrap$Form$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-group')
			]),
		options.bM);
};
var $rundis$elm_bootstrap$Bootstrap$Form$group = F2(
	function (options, children) {
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Form$toAttributes(options),
			children);
	});
var $author$project$AppInput$inputToggleSecure = F7(
	function (msg, placeholder, val, isVisible, toVisibleMsg, label, testAttr) {
		var inputType = isVisible ? $rundis$elm_bootstrap$Bootstrap$Form$Input$text : $rundis$elm_bootstrap$Bootstrap$Form$Input$password;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					inputType(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder(placeholder),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'data-cy', testAttr)
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked(isVisible),
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(toVisibleMsg)
						]),
					'Show Card Number')
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$md = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 2, 0);
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs12 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 12);
var $author$project$Main$paymentDetailsRows = function (model) {
	var donateButtonOrNot = (!model.n) ? _List_fromArray(
		[
			$author$project$Main$donateButton(model)
		]) : _List_Nil;
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A7($author$project$AppInput$inputToggleSecure, $author$project$Main$UpdateCardNumber, 'Card Number*', model.aK, model.bT, $author$project$Main$ToggleCardNumberVisibility, 'Card Number', 'contribCCNumber')
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3Md]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputNumber, $author$project$Main$UpdateExpirationMonth, 'MM*', model.ap, 'contribCCM')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputNumber, $author$project$Main$UpdateExpirationYear, 'YYYY*', model.aq, 'contribCCY')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs12,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs($author$project$Main$mobileColSpacing)
						]),
					_List_fromArray(
						[
							A4($author$project$AppInput$inputSecure, $author$project$Main$UpdateCVV, 'CVV*', model.a7, 'contribCCV')
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md]),
					donateButtonOrNot)
				]))
		]);
};
var $author$project$Main$providePaymentDetailsView = function (model) {
	var title = model.cB ? A2($author$project$Main$titleWithData, 'Payment Details', 'Debit/Credit') : $elm$html$Html$text('Payment Details');
	return A5(
		$author$project$Main$frameContainer,
		model.N,
		title,
		model.b,
		A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
			_List_Nil,
			$author$project$Main$paymentDetailsRows(model)),
		$author$project$Main$OpenPaymentDetails);
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$m3 = $elm$html$Html$Attributes$class('m-3');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3 = $elm$html$Html$Attributes$class('p-3');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb4 = $elm$html$Html$Attributes$class('pb-4');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pt4 = $elm$html$Html$Attributes$class('pt-4');
var $author$project$Copy$reachedMaximumContribution = 'You have reached the maximum contribution limit for our committee. Our team will contact you regarding further contribution methods.';
var $author$project$Main$sendMessageRows = function (model) {
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$m3]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3,
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pt4,
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb4,
									$elm$html$Html$Attributes$class('text-danger')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text($author$project$Copy$reachedMaximumContribution)
								]))
						]))
				]))
		]);
};
var $author$project$Main$stateView = function (model) {
	if (model.cX) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-center text-success display-4'),
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h3,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Thank you for contributing!')
						])),
					$author$project$Main$logoWingDiv
				]));
	} else {
		var donateButtonOrNot = function () {
			var _v0 = _Utils_Tuple2(model.n, model.ay);
			if (_v0.a && (!_v0.b.$)) {
				if (!_v0.b.a) {
					return $author$project$Main$sendMessageRows(model);
				} else {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$m4]),
							_List_fromArray(
								[
									$author$project$Main$donateButton(model)
								]))
						]);
				}
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-form'),
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb5
				]),
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$Main$donationAmountView(model),
						$author$project$Main$provideDonorInfoView(model),
						$author$project$Main$providePaymentDetailsView(model)
					]),
				_Utils_ap(
					donateButtonOrNot,
					_Utils_ap(
						$author$project$Main$footerCopy(model),
						_List_fromArray(
							[$author$project$Main$logoDiv])))));
	}
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb5 = $elm$html$Html$Attributes$class('mb-5');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml5Md = $elm$html$Html$Attributes$class('ml-md-5');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr5Md = $elm$html$Html$Attributes$class('mr-md-5');
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Order1 = 1;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColOrder = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Order = F2(
	function (screenSize, moveCount) {
		return {aS: moveCount, eP: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$order = F2(
	function (size, count) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColOrder(
			A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Order, size, count));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$orderMd1 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$order, 2, 1);
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Order2 = 2;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$orderMd2 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$order, 2, 2);
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$orderXs1 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$order, 0, 1);
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$orderXs2 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$order, 0, 2);
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p5 = $elm$html$Html$Attributes$class('p-5');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl2Md = $elm$html$Html$Attributes$class('pl-md-2');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl3 = $elm$html$Html$Attributes$class('pl-3');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl4Md = $elm$html$Html$Attributes$class('pl-md-4');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr2Md = $elm$html$Html$Attributes$class('pr-md-2');
var $author$project$CommitteePage$committeePage = F4(
	function (promoCopy, promoHeading, headshot, form) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl2Md, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr2Md]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-center'),
								$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p5
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl3,
										$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl4Md,
										$elm$html$Html$Attributes$class('bigger-than-h1')
									]),
								_List_fromArray(
									[promoHeading]))
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$row,
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
										_List_fromArray(
											[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml5Md, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr5Md, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb5]))
									]),
								_List_fromArray(
									[
										A2(
										$rundis$elm_bootstrap$Bootstrap$Grid$col,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Grid$Col$md6,
												$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(_List_Nil),
												$rundis$elm_bootstrap$Bootstrap$Grid$Col$orderXs2,
												$rundis$elm_bootstrap$Bootstrap$Grid$Col$orderMd1
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-black')
													]),
												_List_fromArray(
													[promoCopy])),
												A2(
												$elm$html$Html$div,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$img,
														_List_fromArray(
															[
																$author$project$Asset$src(headshot),
																$elm$html$Html$Attributes$class('w-100')
															]),
														_List_Nil)
													]))
											])),
										A2(
										$rundis$elm_bootstrap$Bootstrap$Grid$col,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Grid$Col$md6,
												$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(_List_Nil),
												$rundis$elm_bootstrap$Bootstrap$Grid$Col$orderXs1,
												$rundis$elm_bootstrap$Bootstrap$Grid$Col$orderMd2
											]),
										_List_fromArray(
											[form]))
									]))
							]))
					]))
			]);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Asset$ianCainHeadshot = $author$project$Asset$image('ian-cain-headshot.png');
var $author$project$Asset$placeholderHeadshot = $author$project$Asset$image('placeholder-headshot.png');
var $author$project$Content$IanCain$firstCopy = 'Your contribution will allow us to do all that we need to make sure that Ian will continue to be YOUR elected representative, and address the needs of the community. We are truly grateful for your support!';
var $author$project$Content$IanCain$firstHeading = 'Donate to The Cain Committee';
var $author$project$Content$Generic$promoCopyFormatter = F2(
	function (heading, copy) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$h4,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]),
				_List_fromArray(
					[
						$elm$html$Html$text(heading)
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(copy)
					]))
			]);
	});
var $author$project$Content$IanCain$promoContent = A2(
	$elm$html$Html$div,
	_List_Nil,
	A2($author$project$Content$Generic$promoCopyFormatter, $author$project$Content$IanCain$firstHeading, $author$project$Content$IanCain$firstCopy));
var $author$project$Content$WillSchweitzer$firstCopy = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
var $author$project$Content$WillSchweitzer$firstHeading = 'Capable';
var $author$project$Content$WillSchweitzer$secondCopy = 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';
var $author$project$Content$WillSchweitzer$secondHeading = 'Responsible';
var $author$project$Content$WillSchweitzer$thirdCopy = 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
var $author$project$Content$WillSchweitzer$thirdHeading = 'Considerate';
var $author$project$Content$WillSchweitzer$promoContent = A2(
	$elm$html$Html$div,
	_List_Nil,
	_Utils_ap(
		A2($author$project$Content$Generic$promoCopyFormatter, $author$project$Content$WillSchweitzer$firstHeading, $author$project$Content$WillSchweitzer$firstCopy),
		_Utils_ap(
			A2($author$project$Content$Generic$promoCopyFormatter, $author$project$Content$WillSchweitzer$secondHeading, $author$project$Content$WillSchweitzer$secondCopy),
			A2($author$project$Content$Generic$promoCopyFormatter, $author$project$Content$WillSchweitzer$thirdHeading, $author$project$Content$WillSchweitzer$thirdCopy))));
var $author$project$Asset$ianCainLogo = $author$project$Asset$image('ian-cain-logo.png');
var $author$project$Content$IanCain$promoHeading = A2(
	$elm$html$Html$img,
	_List_fromArray(
		[
			$author$project$Asset$src($author$project$Asset$ianCainLogo),
			$elm$html$Html$Attributes$class('w-100')
		]),
	_List_Nil);
var $author$project$Content$WillSchweitzer$promoHeading = $elm$html$Html$text('Will Schweitzer for Senator');
var $author$project$CommitteePage$view = F2(
	function (committeeId, form) {
		if ($elm$core$String$length(committeeId) >= 20) {
			return {
				bQ: A4($author$project$CommitteePage$committeePage, $author$project$Content$WillSchweitzer$promoContent, $author$project$Content$WillSchweitzer$promoHeading, $author$project$Asset$placeholderHeadshot, form),
				cZ: 'Will Schweitzer Committee'
			};
		} else {
			switch (committeeId) {
				case 'ian-cain':
					return {
						bQ: A4($author$project$CommitteePage$committeePage, $author$project$Content$IanCain$promoContent, $author$project$Content$IanCain$promoHeading, $author$project$Asset$ianCainHeadshot, form),
						cZ: 'Ian Cain Committee'
					};
				case 'will-schweitzer':
					return {
						bQ: A4($author$project$CommitteePage$committeePage, $author$project$Content$WillSchweitzer$promoContent, $author$project$Content$WillSchweitzer$promoHeading, $author$project$Asset$placeholderHeadshot, form),
						cZ: 'Will Schweitzer Committee'
					};
				default:
					return {
						bQ: _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-center'),
												$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Committee not found')
											]))
									]))
							]),
						cZ: 'Committee not found'
					};
			}
		}
	});
var $author$project$Main$view = function (model) {
	return A2(
		$author$project$CommitteePage$view,
		model.a6,
		$author$project$Main$stateView(model));
};
var $author$project$Main$main = $elm$browser$Browser$document(
	{fz: $author$project$Main$init, fW: $author$project$Main$subscriptions, fZ: $author$project$Main$update, f$: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (jurisdiction) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (host) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (apiEndpoint) {
							return $elm$json$Json$Decode$succeed(
								{c9: apiEndpoint, dp: host, as: jurisdiction});
						},
						A2($elm$json$Json$Decode$field, 'apiEndpoint', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'host', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'jurisdiction', $elm$json$Json$Decode$string)))(0)}});}(this));